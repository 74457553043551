const initialState: any = {};
export default function chatReducer(state: any = initialState, action: any) {
  switch (action.type) {
    case "CREATE_USER":
      return { ...state, createUser: action.payload };

    case "CHECK_USER":
      return { ...state, checkUser: action.payload };

    case "CHECK_ROOM":
      return { ...state, checkRoom: action.payload };

    case "ADD_USER_TO_ROOM":
      return { ...state, addUserToRoom: action.payload };

    case "GET_ALL_ROOMS":
      return { ...state, allRooms: action.payload };

    default:
      return state;
  }
}
