import { makeStyles } from '@material-ui/core';
import React from 'react';
import { colors } from '../../theme';

const useStyles = makeStyles({
    ball: {
        backgroundColor: (props: any) => (props.bgcolor ? props.bgcolor : colors.white),
    },
});

interface props {
    bgcolor?: string;
}

const Loader: React.FC<props> = ({ bgcolor }) => {
    const classes = useStyles({ bgcolor });
    return (
        <div className="dot-wrapper">
            <div className="ball-loader">
                <div className={`ball-loader-ball ball1 ${classes.ball}`}></div>
                <div className={`ball-loader-ball ball2 ${classes.ball}`}></div>
                <div className={`ball-loader-ball ball3 ${classes.ball}`}></div>
            </div>
        </div>
    );
};

export default Loader;
