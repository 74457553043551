export const AUTHENTICATION = 'AUTHENTICATION';
export const GOOGLE_LOGIN = 'GOOGLE_LOGIN';
export const BORROWER_LOGIN = 'BORROWER_LOGIN';
export const LOG_OUT = 'LOG_OUT';
export const LANG = 'LANG';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_REGISTER = 'USER_REGISTER';
export const PROFILE_ACCOUNT_UPDATE = 'PROFILE_ACCOUNT_UPDATE';
export const PROFILE_INFORMATION_UPDATE = 'PROFILE_INFORMATION_UPDATE';
export const UPLOAD_PROFILE_DOCUMENT = 'UPLOAD_PROFILE_DOCUMENT';
export const GET_PROFILE = 'GET_PROFILE';
export const SPINNER = 'SPINNER';
export const MODAL = 'MODAL';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const GET_EMAIL = 'GET_EMAIL';

export const GET_PROFILE_PERSONAL_INFO = 'GET_PROFILE_PERSONAL_INFO';
export const UPDATE_PROFILE_PERSONAL_INFO = 'UPDATE_PROFILE_PERSONAL_INFO';
export const UPLOAD_PROFILE_PERSONAL_DOC = 'UPLOAD_PROFILE_PERSONAL_DOC';

export const LENDER_REGISTRATION = 'LENDER_REGISTRATION';
export const LENDER_LOGIN = 'LENDER_LOGIN';
export const REGISTRATION_FAILED = 'REGISTRATION_FAILED';

export const GET_ALL_DOC_OF_CONSUMER = 'get_all_docs';

export const PROFILE_AVATAR = 'PROFILE_AVATAR';
export const UPLOAD_PROFILE_AVATAR = 'UPLOAD_PROFILE_AVATAR';
export const EMAIL_VERIFIED = 'EMAIL_VERIFIED';
export const PROFILE_UPLOAD = 'PROFILE_UPLOAD';
export const IS_FAILED = 'IS_FAILED';
export const SAVE_STATES = 'SAVE_STATES';
export const USER_PROFILE_INFO = 'USER_PROFILE_INFO';
export const GET_CONSUMER_DATA = 'GET_CONSUMER_DATA';
export const IS_SALE_PROPERTY_FLOW = 'IS_SALE_PROPERTY_FLOW';
