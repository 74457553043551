import {
    GET_INFO,
    UPDATE_INFO,
    VALIDATION_FLAG,
    SPINNER
  } from "../types";

  
  const initialState = {};
  
  function ProfileInfoReducer(state = initialState, action: any) {
    switch (action.type) {
      case GET_INFO:
        return {
          ...state,
          profileInfo: action.payload
        };
      
      case SPINNER:
          return {
            ...state,
            loadSpinner: action.payload
          };
  
      case UPDATE_INFO:
        return {
          ...state,
          updateProfileData: action.payload
        };
      
      case VALIDATION_FLAG:
        return {
          ...state,
          validationFlag:action.validation
        };

      default:
        return state;
    }
  }
  
  export default ProfileInfoReducer;
  