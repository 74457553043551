import { Avatar, Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { colors, Images } from '../../theme';

const useStyles = makeStyles({
    parent: {
        paddingBottom: '1rem',
        paddingLeft: '1rem',
        paddingTop: '1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        backgroundColor: '#fff',
    },
    logo: {
        width: '5.375rem',
        height: '5.375rem',
        marginRight: '2.187rem',
    },
    logoText: {
        fontSize: '3.375rem',
        color: colors.primary,
        fontFamily: 'vivid italic',
    },
});
const Header = () => {
    const classes = useStyles();
    return (
        <Box className={classes.parent}>
            <Avatar src={Images.Logo} className={classes.logo} />
            <Typography className={classes.logoText}>Sleekfin</Typography>
        </Box>
    );
};

export default Header;
