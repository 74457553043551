import {
  GET_NETWORTH_DATA,
  INSERT_NETWORTH_DATA,
  UPDATE_NETWORTH_DATA,
  GET_NETWORTH_DATA_PROPERTY,
  INSERT_NETWORTH_DATA_PROPERTY,
  UPDATE_NETWORTH_DATA_PROPERTY,
  GET_NETWORTH_CARD_DATA,
  SPINNER
} from "../types";

const initialState = {
  totalActiveLoan: {},
  loadSpinner:false
};

function NetWorthReducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_NETWORTH_DATA:
      return {
        ...state,
        getNetWorthData: action.payload
      };

    case SPINNER:
        return {
          ...state,
          loadSpinner: action.payload
        };
    case INSERT_NETWORTH_DATA:
      return {
        ...state,
        insertNetWorthData: action.payload
      };

    case UPDATE_NETWORTH_DATA:
      return {
        ...state,
        updateNetWorthData: action.payload
      };

    case GET_NETWORTH_DATA_PROPERTY:
      return {
        ...state,
        getNetWorthPropertyData: action.payload
      };

    case INSERT_NETWORTH_DATA_PROPERTY:
      return {
        ...state,
        insertNetWorthPropertyData: action.payload
      };

    case UPDATE_NETWORTH_DATA_PROPERTY:
      return {
        ...state,
        updateNetWorthPropertyData: action.payload
      };


      case GET_NETWORTH_CARD_DATA:
        return {
          ...state,
          netWorthCardData: action.payload
        };

    default:
      return state;
  }
}

export default NetWorthReducer;
