import { toast } from 'react-toastify';
import { socialRegisterTypes, socialLoginTypes } from '../../Interfaces/authTypes';
import { API, setAuthrizationToken } from '../../utility/api';
import { endpoints } from '../../utility/endpoints';
import { toastMessages } from '../../utility/toastMessages';
// import { loginData } from './authAction';
import { handleError, handleRedirection } from './errorHandler';
import { getConsumerData, getProfilePersonalInfo } from './profileAction';
import { borrowerLogin } from './userActions';

export const builderSocialRegisterAPIcall = (data: socialRegisterTypes) => {
    return (dispatch: any) => {
        API.post(endpoints.socialRegister, data)
            .then((res: any) => {
                toast.success(toastMessages.accountCreated);
            })
            .catch((err: any) => {
                handleError(dispatch, err);
                handleRedirection(err);
            });
    };
};

export const builderSocialLoginAPIcall = (data: socialLoginTypes, history: any) => {
    return (dispatch: any) => {
        API.post(endpoints.socialLogin, data)
            .then(async (res: any) => {
                // dispatch(loginData(res.data));
                localStorage.setItem('token', JSON.stringify(res.data.token));
                localStorage.setItem('email', JSON.stringify(res.data.responseObject.primaryEmail));
                localStorage.setItem('auth', JSON.stringify(res.data));
                setAuthrizationToken();
                await setAuthrizationToken();
                await dispatch(borrowerLogin(res.data));
                await dispatch(getConsumerData());
                await dispatch(getProfilePersonalInfo());
                history.push('/dashboard/');
            })
            .catch((err: any) => {
                handleError(dispatch, err);
                handleRedirection(err);
            });
    };
};
