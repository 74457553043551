import { useEffect, useState } from 'react';
import packageJson from '../../package.json';
global.appVersion = packageJson.version;

const semverGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split(/\./g);
    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift());
        const b = Number(versionsB.shift());
        // eslint-disable-next-line no-continue
        if (a === b) continue;
        // eslint-disable-next-line no-restricted-globals
        return a > b || isNaN(b);
    }
    return false;
};

const CacheBusting = (props) => {
    const refresh = () => {
        console.log('Clearing cache and hard reloading...');
        if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(function (names) {
                for (let name of names) caches.delete(name);
            });
        }
        // delete browser cache and hard reload
        window.location.reload(true);
    };

    const [state, setState] = useState({
        loading: true,
        isLatestVersion: false,
        refreshCacheAndReload: refresh,
    });

    useEffect(() => {
        fetch('/meta.json')
            .then((res) => res.json())
            .then((meta) => {
                const latestVersion = meta.version;
                const currentVersion = global.appVersion;
                const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
                if (shouldForceRefresh) {
                    console.log(`We have a new version - ${latestVersion}. Should force refresh`);
                    setState({ ...state, loading: false, isLatestVersion: false });
                } else {
                    console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
                    setState({ ...state, loading: false, isLatestVersion: true });
                }
            });
    }, []);
    const { loading, isLatestVersion, refreshCacheAndReload } = state;
    return props.children({ loading, isLatestVersion, refreshCacheAndReload });
};

export default CacheBusting;
