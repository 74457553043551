
import { LOADER } from "../types";
import { SPINNER, MODAL, LOGIN_FAILED, REGISTRATION_FAILED, EMAIL_VERIFIED, IS_FAILED } from "../types/userTypes";

const initialState = {
  showSpinner: false,
  showModal: false,
  loginFailed: false,
  registrationFaild: false,
  emailVerFailed: false,
  isFailed:false,
  loader: false,
}

function uiReducer(state = initialState, action: any) {
  switch (action.type) {
    case SPINNER:
      return {
        ...state,
        showSpinner: action.payload
      }
    case MODAL:
      return {
        ...state,
        showModal: action.payload
      }
    case LOGIN_FAILED:
      return {
        ...state,
        loginFailed:action.payload
      }
    case REGISTRATION_FAILED:
      return {
        ...state,
        registrationFaild: action.payload
      }
    case EMAIL_VERIFIED: {
      return {
        ...state,
        emailVerFailed: action.payload
      }
    }
    case IS_FAILED: {
      return {
        ...state,
        isFailed: action.payload
      }
    }
    case LOADER: {
      return {
        ...state,
        loader: action.payload
      }
    }
    default:
      return state;
  }
}

export default uiReducer;


