import React, { useState, useEffect } from 'react';
import FormContainer from './FormContainerForm';
import { useHistory } from 'react-router-dom';
import Header from '../../components/Header/Header';
import { useSelector } from 'react-redux';
import Footer from '../../components/Footer/Footer';
import { Box, Grid, Typography } from '@material-ui/core';
import { colors, Images } from '../../theme';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

const useStyles = makeStyles({
    wrapper: {
        position: 'fixed',
        zIndex: 3,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        animation: 'fadeIn 1s linear',
        maxHeight: '100vh',
    },
    imageWrapper: {
        position: 'relative',
        '&:before': {
            display: 'block',
            content: '""',
            paddingBottom: '55%',
        },
        '& img': {
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
        },
    },
    fix: {
        maxHeight: '100vh',
    },
    slideup: {
        transform: 'translateY(-140%)',
        animation: 'slideup 0.7s linear',
    },
    fadeIn: {
        opacity: 1,
        animation: 'fadeIn 2s linear',
    },
    hide: {
        display: 'none',
    },
    message: {
        // backgroundColor: colors.primary,
        color: colors.white,
        padding: '.5rem',
        width: '100%',
        textAlign: 'center',
        marginBottom: '1rem',
        textShadow: '0 0 5px rgba(0,0,0,1)',
        // borderRadius: '0.5rem',
        fontSize: '3rem',
        fontWeight: 500,
        '@media (min-width:1920px)': {
            fontSize: '3.5rem',
        },
    },
    videoContainer: {
        width: '100%',
        marginBottom: '1rem',
    },
    videoWrapper: {
        transition: '.2s linear',
        '&:hover': {
            transform: 'scale(1.05)',
            boxShadow: '0 0 20px -1px rgba(0,0,0,0.2)',
        },
        '& p': {
            fontSize: '1rem',
            color: colors.white,
            fontWeight: 500,
            backgroundColor: colors.primary,
            textAlign: 'center',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
    video: {
        width: '100%',
        height: '10rem',
    },
});

const Login: React.FunctionComponent = () => {
    const classes = useStyles();
    const route = useHistory();
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setAnimate(true);
        }, 4000);
    }, []);

    return (
        <Box>
            <Box className={classNames(classes.wrapper, { [classes.slideup]: animate })}>
                <Box className={classes.imageWrapper}>
                    <img src={Images.splashScreen} alt="splash-screen" />
                </Box>
            </Box>
            <div className={`component-wrapper ${classNames({ [classes.hide]: !animate, [classes.fadeIn]: animate })}`}>
                <Header />
                <div className="form-container d-flex">
                    <div className="row w-100 m-0">
                        <div className="col-lg-4 col-md-6 d-flex align-items-center justify-content-center">
                            <FormContainer pathname="/dashboard/" />
                        </div>
                        <div className="col-lg-3 col-md-1 d-flex"></div>
                        <div className="col-lg-5 col-md-5 d-flex">
                            <div className="slogan-box-container">
                                <Box className={classes.videoContainer}>
                                    <Typography className={classes.message}>
                                        One stop shop, for your home and loan, matching your lifestyle
                                    </Typography>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <Box className={classes.videoWrapper}>
                                                <Typography>Sleekfin Promotional Video</Typography>
                                                <iframe
                                                    className={classes.video}
                                                    src="https://www.youtube.com/embed/j4ujd4gCoxw"
                                                    title="YouTube video player"
                                                    frameBorder={0}
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <Box className={classes.videoWrapper}>
                                                <Typography>Sleekfin Consumer Mobile App Demo</Typography>
                                                <iframe
                                                    className={classes.video}
                                                    src="https://www.youtube.com/embed/CjWj--i2bNY"
                                                    title="YouTube video player"
                                                    frameBorder={0}
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </Box>
    );
};

export default Login;
