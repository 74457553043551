import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { colors } from '../../theme';

const useStyles = makeStyles({
    parent: {
        width: '2.437rem',
        height: '2.437rem',
        borderRadius: '50%',
        border: `2px solid ${colors.darkGray}`,
        cursor: 'pointer',
        margin: '0 2px',
        '&:hover': {
            border: `2px solid ${colors.primary}`,
            '& svg': {
                color: colors.primary,
            },
        },
    },
    icon: {
        color: colors.darkGray,
    },
});

interface props {
    icon?: any;
    link: string;
}
const Tile: React.FC<props> = ({ icon, link }) => {
    const classes = useStyles();
    const goToPage = () => {
        window.location.href = link;
    };
    return (
        <Grid container alignItems="center" justify="center" onClick={goToPage} className={classes.parent}>
            <FontAwesomeIcon icon={icon} className={classes.icon} />
        </Grid>
    );
};

export default Tile;
