import React, { useEffect, FC, useState } from 'react';
import { TextField, Button, Typography, makeStyles, Box, Grid } from '@material-ui/core';
import { useHistory, Link, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { loginValidation } from '../../Helper/FormValidations/loginValidation';
import ShowErrors from '../../components/UI/ShowErrors';
import NormalButton from '../../components/Buttons/NormalButton';
import { loginWithEmailPass, loginWithMobilenumber, loginWithMobileOTP } from '../../redux/actions/userActions';
import { routingEndPoints } from '../../utility/routingEndPoints';
import { colors } from '../../theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAndroid, faApple, faAppStore } from '@fortawesome/free-brands-svg-icons';
import FbGoogleLogin from '../../components/fbGoogleLogin/FbGoogleLogin';
import ModalComponent from '../../components/Modal/ModalComponent';
import FooterLinks from '../../components/Footer/FooterLinks';
// import { getLocalStorageData } from '../../utility/getLocalStorageData';

const useStyle = makeStyles({
    availabe: {
        backgroundColor: colors.darkGray,
        borderRadius: '0.3rem',
        margin: '1rem 0',
    },
    iconContainer: {
        width: '3.5rem',
        height: '3.5rem',
        borderRight: `1px solid rgba(225,225,225, 0.5)`,
        marginRight: '1rem',
    },
    title: {
        fontSize: '1rem',
        lineHeight: '1rem',
        fontWeight: 100,
        color: '#fff',
        marginBottom: 0,
    },
    subTitle: {
        fontSize: '1rem',
        lineHeight: '1rem',
        fontWeight: 500,
        color: '#fff',
        marginBottom: 0,
    },
    icon: {
        color: '#fff',
        fontSize: '2rem',
    },
    comingSoon: {
        fontSize: '2rem',
        color: colors.primary,
        textAlign: 'center',
        fontWeight: 800,
    },
});

interface Props {
    pathname: string;
    isAnonymous?: boolean;
    hideModal?: () => void;
}

const LoginForm: FC<Props> = ({ pathname, isAnonymous = false, hideModal = () => undefined }) => {
    const routes = useHistory();
    const classes = useStyle();
    const [isOpen, setIsOpen] = useState(false);
    const [showOtp, setShowOtp] = useState(false);
    const [sessionId, setSessionId] = useState('');

    useEffect(() => {
        if (process.env.REACT_APP_ENVIRONMENT === 'development') {
            reset({ email: 'test14@mailinator.com', password: 'Test@123' });
        }
    }, []);
    const dispatch = useDispatch();
    const { register, errors, handleSubmit, reset } = useForm({
        validationSchema: isOpen ? loginValidation.mobileValidation : loginValidation.validationSetting,
    });
    const submit = (values: any) => {
        const data = {
            email: values.email,
            password: values.password,
        };
        if (!isOpen) {
            loginWithEmailPass(data, routes, pathname, isAnonymous, hideModal);
        }
    };

    const handleModal = (value: boolean) => {
        setIsOpen(value);
    };

    const handleMobileLogin = async (data: any) => {
        if (!showOtp) {
            const result = await loginWithMobilenumber(data.mobileNumber);
            if (result.status === 200) {
                setSessionId(result.data.responseObject.details);
                setShowOtp(true);
            }
        } else {
            loginWithMobileOTP(sessionId, data.otp, routes, pathname);
        }
    };

    const handleModalClose = () => {
        setIsOpen(false);
        setShowOtp(false);
        setSessionId('');
    };

    return (
        <>
            <ModalComponent openOrNot={isOpen} width="27rem" handleClose={handleModalClose}>
                <form style={{ width: '100%' }} onSubmit={handleSubmit(handleMobileLogin)}>
                    <TextField
                        className="custom-input mb-4"
                        label="Enter your mobile number"
                        name="mobileNumber"
                        variant="outlined"
                        type="text"
                        inputRef={register}
                        disabled={showOtp}
                        fullWidth
                    />
                    {showOtp && (
                        <TextField
                            className="custom-input mb-4"
                            label="Enter OTP"
                            name="otp"
                            inputRef={register}
                            variant="outlined"
                            type="text"
                            fullWidth
                        />
                    )}
                    {errors.mobileNumber && <ShowErrors error={errors.mobileNumber.message} />}
                    <NormalButton type="submit" text={showOtp ? 'Submit' : 'Send OTP'} />
                </form>
            </ModalComponent>
            <div className="login-form">
                <Typography className="heading">Login</Typography>
                <form onSubmit={handleSubmit(submit)}>
                    <TextField className="custom-input mb-4" inputRef={register} label="Email" name="email" variant="outlined" fullWidth />
                    {errors.email && <ShowErrors error={errors.email.message} />}
                    <TextField
                        className="custom-input mb-4"
                        label="Password"
                        name="password"
                        variant="outlined"
                        type="password"
                        inputRef={register}
                        fullWidth
                    />
                    {errors.password && <ShowErrors error={errors.password.message} />}
                    <NormalButton type="submit" text="Login" />
                </form>
                <Link to="/forgot-password" className="link__style">
                    Forgot password?
                </Link>
                <Link to="/signup" className="link__style">
                    Create an account?
                </Link>
                <Grid container alignItems="center" className={classes.availabe}>
                    <Grid container alignItems="center" justify="center" className={classes.iconContainer}>
                        <FontAwesomeIcon icon={faApple} className={classes.icon} />
                    </Grid>
                    <Box>
                        <Typography className={classes.title}>Availabe on the</Typography>
                        <Typography className={classes.subTitle}>APP STORE</Typography>
                    </Box>
                </Grid>

                <Grid container alignItems="center" className={classes.availabe}>
                    <Grid container alignItems="center" justify="center" className={classes.iconContainer}>
                        <FontAwesomeIcon icon={faAndroid} className={classes.icon} />
                    </Grid>
                    <Box>
                        <Typography className={classes.title}>Availabe on the</Typography>
                        <Typography className={classes.subTitle}>PLAY STORE</Typography>
                    </Box>
                </Grid>
                <Typography className="title">
                    It is so <span>simple!</span> <br /> You can close your loan <br /> just on your <span>phone!</span>
                </Typography>
                <FbGoogleLogin handleMobileLogin={handleModal} />
                <FooterLinks />
            </div>
        </>
    );
};

export default LoginForm;
