import React, { useEffect } from 'react';
import { TextField, Button, Typography } from '@material-ui/core';
import { useHistory, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// import { signupAPIcall } from '../../Redux/Actions/authAction';
import { useForm } from 'react-hook-form';
import { signupValidation } from '../../Helper/FormValidations/signupValidation';
import ShowErrors from '../../components/UI/ShowErrors';
import NormalButton from '../../components/Buttons/NormalButton';
import { userRegister } from '../../redux/actions/userActions';
import FbGoogleRegister from '../../components/fbGoogleLogin/FbGoogleRegister';

const FormContainer = () => {
    const routes = useHistory();
    const loader = useSelector((state: any) => state.uiReducer.loader);
    const dispatch = useDispatch();
    const { register, errors, handleSubmit, reset } = useForm({
        validationSchema: signupValidation.validationSetting,
    });
    const resetFields = () => {
        reset({ email: '', password: '', confirmPassword: '' });
    };
    const submit = async (data: any) => {
        const agentData = {
            confirmPassword: data.password,
            email: data.email,
            password: data.confirmPassword,
            firstName: '',
            lastName: '',
            mobileNumber: '',
            userName: '',
            userStatus: 'active',
        };
        userRegister(agentData, resetFields);
    };

    return (
        <div className="login-form">
            <Typography className="heading">Create An Account</Typography>
            <form onSubmit={handleSubmit(submit)}>
                <TextField className="custom-input mb-4" inputRef={register} label="Email" name="email" variant="outlined" fullWidth />
                {errors.email && <ShowErrors error={errors.email.message} />}
                <TextField
                    className="custom-input mb-4"
                    label="Password"
                    name="password"
                    variant="outlined"
                    type="password"
                    inputRef={register}
                    fullWidth
                />
                {errors.password && <ShowErrors error={errors.password.message} />}
                <TextField
                    className="custom-input mb-4"
                    label="Confirm Password"
                    name="confirmPassword"
                    variant="outlined"
                    type="password"
                    inputRef={register}
                    fullWidth
                />
                {errors.confirmPassword && <ShowErrors error={errors.confirmPassword.message} />}
                <NormalButton type="submit" text="Create an account" />
            </form>
            <Link to="/" className="link__style">
                Already have an account?
            </Link>
            <Typography className="title">
                It is so <span>simple!</span> <br />
                Let borrowers come to you.
            </Typography>
            <FbGoogleRegister />
        </div>
    );
};

export default FormContainer;
