export const signInWithPopup = async (provider: any) => {
    return (window as any).firebase.auth().signInWithPopup(provider());
};

export const socialLogout = () => {
    (window as any).firebase
        .auth()
        .signOut()
        .then(function () {
            // Sign-out successful.
            console.log('Sign-out successful.');
        })
        .catch(function () {
            // An error happened.
            console.log('Sign-out failed.');
        });
};
