import { LANG } from '../types/userTypes';

const initialState = {
    lang: 'en',
};

function langReducer(state = initialState, action: any) {
    switch (action.type) {
        case LANG: {
            return {
                ...state,
                lang: action.payload,
            };
        }
        default:
            return state;
    }
}

export default langReducer;
