import React from 'react';
import { Typography } from '@material-ui/core';
import FormContainer from './FormContainer';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

const Signup: React.FunctionComponent = () => {
    return (
        <div className="component-wrapper">
            <Header />
            <div className="form-container">
                <div className="row">
                    <div className="col-md-4 d-flex align-items-center justify-content-center">
                        <FormContainer />
                    </div>
                    <div className="col-md-8 d-flex">
                        <div className="slogan-box-container">
                            <div className="slogan-box">
                                {/* <Typography className="slogan">
                                    Sell your property to prequalified buyers <br /> List it for free <br /> Pay only
                                    0.25% fee after it sold.
                                </Typography> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Signup;
