import {
    GET_ACCOUNT_TYPE_DROPDOWN,
    GET_ASSET_TYPE_DROPDOWN,
    GET_STATUS_DROPDOWN,
    GET_LOAN_TYPE_DROPDOWN,
    GET_GIFT_ASSET_TYPE_DROPDOWN,
    GET_INCOME_OTHER_SOURCE_TYPE_DROPDOWN,
    GET_SOURCE_DROPDOWN,
    GET_PHONE_NUMBER_DROPDOWN,
    GET_HISPANIC_DROPDOWN,
    GET_ASIAN_DROPDOWN,
    GET_NATIVE_HAWAILIAN_DROPDOWN,
    GET_NOTIFICATION_DROPDOWN,
    GET_GENERAL_LIABILITY_DROPDOWN,
} from '../types/dropdownType';
import { DROPDOWNS } from '../types/filterTypes';

const initialState = {
    dropdown: null,
};

function dropdownReducer(state = initialState, action: any) {
    switch (action.type) {
        case GET_ACCOUNT_TYPE_DROPDOWN: {
            return {
                ...state,
                accountType: action.payload,
            };
        }
        case GET_ASSET_TYPE_DROPDOWN: {
            return {
                ...state,
                assetType: action.payload,
            };
        }
        case GET_STATUS_DROPDOWN: {
            return {
                ...state,
                status: action.payload,
            };
        }
        case GET_LOAN_TYPE_DROPDOWN: {
            return {
                ...state,
                loanType: action.payload,
            };
        }
        case GET_GIFT_ASSET_TYPE_DROPDOWN: {
            return {
                ...state,
                giftAssetType: action.payload,
            };
        }
        case GET_INCOME_OTHER_SOURCE_TYPE_DROPDOWN: {
            return {
                ...state,
                incomeOtherSource: action.payload,
            };
        }
        case GET_SOURCE_DROPDOWN: {
            return {
                ...state,
                source: action.payload,
            };
        }
        case GET_PHONE_NUMBER_DROPDOWN: {
            return {
                ...state,
                phoneNumber: action.payload,
            };
        }
        case GET_HISPANIC_DROPDOWN: {
            return {
                ...state,
                hispanic: action.payload,
            };
        }
        case GET_ASIAN_DROPDOWN: {
            return {
                ...state,
                asian: action.payload,
            };
        }
        case GET_NATIVE_HAWAILIAN_DROPDOWN: {
            return {
                ...state,
                nativeHawailian: action.payload,
            };
        }
        case GET_NOTIFICATION_DROPDOWN: {
            return {
                ...state,
                notification: action.payload,
            };
        }

        case GET_GENERAL_LIABILITY_DROPDOWN: {
            return {
                ...state,
                generalLiability: action.payload,
            };
        }
        case DROPDOWNS: {
            return {
                ...state,
                dropdown: action.payload,
            };
        }

        default:
            return state;
    }
}

export default dropdownReducer;
