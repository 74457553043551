import * as yup from 'yup';
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const loginValidation = {
    validationSetting: yup.object().shape({
        email: yup.string().required().email(),
        password: yup.string().required(),
    }),
    mobileValidation: yup.object().shape({
        mobileNumber: yup.string().matches(phoneRegExp, 'Phone number is not valid'),
    }),
};
