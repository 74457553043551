import { MAKE_BID } from "../types/bidType";

const initialState = {
  totalActiveLoan: {}
};

function dashboardReducer(state = initialState, action: any) {
  switch (action.type) {
    case MAKE_BID:
      return {
        ...state,
        totalActiveLoan: action.payload
      };
    case "GET_CONSUMER_BIDS":
      return {
        ...state,
        allBids: action.payload
      };
    default:
      return state;
  }
}

export default dashboardReducer;
