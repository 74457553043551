import {
    PROFILE_ACCOUNT_UPDATE,
    PROFILE_INFORMATION_UPDATE,
    UPLOAD_PROFILE_DOCUMENT,
    GET_PROFILE,
    GET_PROFILE_PERSONAL_INFO,
    UPDATE_PROFILE_PERSONAL_INFO,
    UPLOAD_PROFILE_PERSONAL_DOC,
    GET_ALL_DOC_OF_CONSUMER,
    SAVE_STATES,
    GET_CONSUMER_DATA,
} from '../types/userTypes';
import endPoint from '../../utility/urlEndPoints';
import Axios from 'axios';
import { borrowerBaseUrl } from '../../utility/baseUrls';
import { store } from '../store';
import { isLoadingShow, isModalShow, resetAllDataResonse401, logOut, logoutNreset, isAuthenticated } from './userActions';
import { API, API_URL } from '../../utility/api';
import { handleRedirection } from './errorHandler';
const dispatch = store.dispatch;
const authToken = store.getState().userReducer.accessToken;

export function geturl() {
    const url = Axios.create({
        baseURL: API_URL,
        headers: {
            'content-type': 'Application/json',
            authToken: store.getState().userReducer.accessToken,
        },
    });

    return url;
}

export const linkwithtoken = Axios.create({
    baseURL: borrowerBaseUrl,
    headers: {
        'content-type': 'Application/json',
        authToken: store.getState().userReducer.accessToken,
    },
});

export function getProfileData(data: object) {
    return {
        type: GET_PROFILE,
        payload: data,
    };
}

export function updateProAccInfoRes(data: object) {
    return {
        type: PROFILE_ACCOUNT_UPDATE,
        payload: data,
    };
}

export function updateProPersInfoRes(data: object) {
    return {
        type: PROFILE_INFORMATION_UPDATE,
        payload: data,
    };
}

export function uploadDocument(data: object) {
    return {
        type: UPLOAD_PROFILE_DOCUMENT,
        payload: data,
    };
}
export function getProfile() {
    geturl()
        .get(endPoint.profile)
        .then((res) => dispatch(getProfileData(res.data)))
        .catch((err) => {
            resetAllDataResonse401(err);
            dispatch(isAuthenticated(false));
        });
}

export function updateProfileAccInfo(data: object) {
    dispatch(isLoadingShow(true));
    geturl()
        .put(endPoint.updatePro, data)
        .then((res) => {
            dispatch(isLoadingShow(false));
            dispatch(isModalShow(true));
            geturl()
                .get(endPoint.profile)
                .then((res) => dispatch(getProfileData(res.data)))
                .catch((err) => {});
        })
        .catch((err) => {
            dispatch(isLoadingShow(false));
            handleRedirection(err);
        });
}

export function updateProfilePersInfo() {
    linkwithtoken
        .put(endPoint.updatePro)
        .then((res) => console.log(res))
        .catch((err) => {});
}

export function uploadProfileDocs() {
    linkwithtoken
        .put(endPoint.updatePro)
        .then((res) => {})
        .catch((err) => {
            handleRedirection(err);
        });
}

export function saveGetProfile(data: object) {
    return {
        type: GET_PROFILE_PERSONAL_INFO,
        payload: data,
    };
}

export function saveProfileDocs(data: object) {
    return {
        type: 'GET_PROFILE_DOCS',
        payload: data,
    };
}

export function getProfilePersonalInfo(id?: number, token?: string) {
    const req = geturl()
        .get(`${borrowerBaseUrl}/getconsumer/${id || 0}`)
        .then((res) => {
            if (res.data.status === 200) {
                dispatch(saveGetProfile(res.data));
                dispatch(getConsumerData(res.data.responseObject));
                return res.data;
            } else {
                // dispatch(logOut());
                dispatch(isAuthenticated(false));
            }
        })
        .catch((err) => {
            // alert("Session Expired!");
            // window.location.href = "/";
            // dispatch(logoutNreset());
            handleRedirection(err);
            return false;
        });
    return {
        type: 'GET_PROFILE_PERSONAL_INFO_METHOD',
        payload: req,
    };
}

export async function updateConsumerData(data: any) {
    const req = API.put(`/consumer`, data)
        .then((res: any) => {
            if (res.data.status === 200) {
                // dispatch(saveGetProfile(res.data));
                // dispatch(getConsumerData(res.data.responseObject));
                return res.data;
            }
        })
        .catch((err: any) => {
            handleRedirection(err);
            return err;
        });
    return {
        type: 'GET_PROFILE_PERSONAL_INFO_METHOD',
        payload: req,
    };
}

export const saveConsumerData = (data: any) => {
    return {
        type: GET_CONSUMER_DATA,
        payload: data,
    };
};

export async function getConsumerData(data?: any) {
    const req = API.get(`/consumer`)
        .then((res: any) => {
            if (res.data.status === 200) {
                const data = res.data.responseObject;
                dispatch(saveConsumerData(data));
                return data;
            }
            return null;
        })
        .catch((err: any) => {
            handleRedirection(err);
            console.log(err.response);
            return null;
        });
    return {
        type: 'GET_CONSUMER_DATA',
        payload: req,
    };
}

export function updatePersonelInfo(data: any) {
    const req = geturl()
        .put(`${borrowerBaseUrl}/consumer`, data)
        .then((res) => res.data)
        .catch((err) => {
            handleRedirection(err);
        });

    return {
        type: UPDATE_PROFILE_PERSONAL_INFO,
        payload: req,
    };
}

export function changePassword(data: object) {
    const req = geturl()
        .put(`${borrowerBaseUrl}/change/password`, data)
        .then((res) => {
            if (res.data.status === 200) {
                setTimeout(() => alert('Your Password successfully changed!'), 100);
            }
        })
        .catch((err) => {
            handleRedirection(err);
            setTimeout(() => alert('Something Went Wrong!\nPlease try again'), 100);
        });
    return {
        type: '1234567',
        payload: req,
    };
}

export function uploadPersonalInfoDoc(data: any, consumerId: number) {
    dispatch(isLoadingShow(true));
    const req = geturl()
        .post(`${borrowerBaseUrl}/document`, data, {})
        .then((res) => {
            if (res.data.status === 200) {
                dispatch(getAllDocOfConsumer(consumerId));
                dispatch(isLoadingShow(false));
                setTimeout(() => alert('Document uploaded successfully'), 100);
            }
        })
        .catch((err) => {
            handleRedirection(err);
            dispatch(isLoadingShow(false));
        });

    return {
        type: UPLOAD_PROFILE_PERSONAL_DOC,
        payload: req,
    };
}

export function getAllDocOfConsumer(consumerId: number) {
    const req = geturl()
        .get(`${borrowerBaseUrl}/document`)
        .then((res) => {
            dispatch(saveProfileDocs(res.data));
        })
        .catch((err) => {
            handleRedirection(err);
        });
    return {
        type: GET_ALL_DOC_OF_CONSUMER,
        payload: req,
    };
}

export function makeLoanRequest(data: object) {
    dispatch(isLoadingShow(true));
    geturl()
        .post(endPoint.loanReq, data)
        .then((res) => {
            dispatch(isLoadingShow(false));
            setTimeout(() => alert('Your loan request has been successfully submitted.'), 100);
        })
        .catch((err) => {
            handleRedirection(err);
            setTimeout(() => alert('Unable to make request. something went wrong.'), 100);
            dispatch(isLoadingShow(false));
        });
}

export function saveState(data: object) {
    return {
        type: SAVE_STATES,
        payload: data,
    };
}

export function getState() {
    const country = 'United States';
    geturl()
        .get(`${endPoint.getState}?country=${country}`)
        .then((res) => {
            dispatch(saveState(res.data));
        })
        .catch((err) => {
            handleRedirection(err);
        });
}
