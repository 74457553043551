import {
  GET_INCOME_DATA,
  INSERT_INCOME_DATA,
  UPDATE_INCOME_DATA,
  DETELE_INCOME_DATA,
  UPDATE_OTHER_INCOME_DATA,
  GET_OTHER_INCOME_DATA
} from "../types";

const initialState = {};

function IncomeReducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_INCOME_DATA:
      return {
        ...state,
        getIncomeData: action.payload
      };

    case GET_OTHER_INCOME_DATA:
      return {
        ...state,
        getOtherIncomeData: action.payload
      };

    case INSERT_INCOME_DATA:
      return {
        ...state,
        insertIncomeData: action.payload
      };

    case UPDATE_INCOME_DATA:
      return {
        ...state,
        incomeData: action.payload
      };

    case UPDATE_OTHER_INCOME_DATA:
      return {
        ...state,
        otherIncomeData: action.payload
      };

    case DETELE_INCOME_DATA:
      return {
        ...state,
        deleteIncomeData: action.payload
      };

    case "IncomeCardData":
      return {
        ...state,
        IncomeCardData: action.payload
      };

    default:
      return state;
  }
}

export default IncomeReducer;
