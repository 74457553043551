import { store } from "../redux/store";
import eng from "./en.json";


export const translation = {
  interpolation: {
    escapeValue: false,
  },
  lng: store.getState().langReducer.lang,
  resources: {
    en: {
      translation: {
        ...eng
      },
    },
    es: {
      translation: {

      },
    }
  }
}
