import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap/dist/css/bootstrap-grid.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "hover.css/scss/hover.scss";
import "./theme/reset.css"
import "animate.css";
import "./theme/index.scss";
import Routing from "./Routing";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import { translation } from "./i18n/translation";
import 'react-app-polyfill/stable';

i18next.init(translation);
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // dev mode
  console.warn = console.error = () => {};
} else {
  // production mode
  // console.log = console.warn = console.error = () => {};
}
// console.log = console.warn = console.error = () => {};
ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <Provider store={store}>
      <Routing />
    </Provider>
  </I18nextProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
