const GET_DROPDOWN: string = "GET_DROPDOWN";
const GET_ACCOUNT_TYPE_DROPDOWN: string = "GET_ACCOUNT_TYPE_DROPDOWN";
const GET_ASSET_TYPE_DROPDOWN: string = "GET_ASSET_TYPE_DROPDOWN";
const GET_STATUS_DROPDOWN: string = "GET_STATUS_DROPDOWN";
const GET_LOAN_TYPE_DROPDOWN: string = "GET_LOAN_TYPE_DROPDOWN";
const GET_GIFT_ASSET_TYPE_DROPDOWN: string = "GET_GIFT_ASSET_TYPE_DROPDOWN";
const GET_INCOME_OTHER_SOURCE_TYPE_DROPDOWN: string =
  "GET_INCOME_OTHER_SOURCE_TYPE_DROPDOWN";
const GET_SOURCE_DROPDOWN: string = "GET_SOURCE_DROPDOWN";
const GET_PHONE_NUMBER_DROPDOWN: string = "GET_PHONE_NUMBER_DROPDOWN";
const GET_HISPANIC_DROPDOWN: string = "GET_HISPANIC_DROPDOWN";
const GET_ASIAN_DROPDOWN: string = "GET_ASIAN_DROPDOWN";
const GET_NATIVE_HAWAILIAN_DROPDOWN: string = "GET_NATIVE_HAWAILIAN_DROPDOWN";
const GET_NOTIFICATION_DROPDOWN: string = "GET_NOTIFICATION_DROPDOWN";

const GET_GENERAL_LIABILITY_DROPDOWN: string = "GET_GENERAL_LIABILITY_DROPDOWN";

export {
  GET_DROPDOWN,
  GET_ACCOUNT_TYPE_DROPDOWN,
  GET_GENERAL_LIABILITY_DROPDOWN,
  GET_ASSET_TYPE_DROPDOWN,
  GET_STATUS_DROPDOWN,
  GET_LOAN_TYPE_DROPDOWN,
  GET_GIFT_ASSET_TYPE_DROPDOWN,
  GET_INCOME_OTHER_SOURCE_TYPE_DROPDOWN,
  GET_SOURCE_DROPDOWN,
  GET_PHONE_NUMBER_DROPDOWN,
  GET_HISPANIC_DROPDOWN,
  GET_ASIAN_DROPDOWN,
  GET_NATIVE_HAWAILIAN_DROPDOWN,
  GET_NOTIFICATION_DROPDOWN,
  
};
