import { combineReducers } from 'redux';
import userReducer from './reducer/userReducer';
import langReducer from './reducer/langReducer';
import uiReducer from './reducer/uiReducer';
import dashboardReducer from './reducer/dashboardReducer';
import bidReducer from './reducer/bidReducer';
import creditScoreReducer from './reducer/creditScoreReducer';
import LiabilityReducer from './reducer/liabilityReducer';
import NetWorthReducer from './reducer/netWorthReducer';
import commonFileUploadReducer from './reducer/commonFileUploadReducer';
import dropdownReducer from './reducer/dropdownReducer';
import IncomeReducer from './reducer/incomeReducer';
import ProfileInfoReducer from './reducer/profileInfoReducers';
import AuctionReducer from './reducer/auctionReducers';
import chatReducer from './reducer/chatReducer';
import PreApprovalReducer from './reducer/preApprovalReducers';
import formValidationReducer from './reducer/formValidationReducer';
import incomeNewReducer from './reducer/incomeNewReducer';
import cometChatReducer from './reducer/cometChatReducer';
import propertyReducer from './reducer/propertyReduer';
import loanReducer from './reducer/loanReducer';
import offerReducer from './reducer/offerReducer';

export const rootReducer = combineReducers({
    userReducer,
    langReducer,
    uiReducer,
    dashboardReducer,
    bidReducer,
    creditScoreReducer,
    LiabilityReducer,
    NetWorthReducer,
    commonFileUploadReducer,
    dropdownReducer,
    IncomeReducer,
    ProfileInfoReducer,
    AuctionReducer,
    chatReducer,
    PreApprovalReducer,
    formValidationReducer,
    incomeNewReducer,
    cometChatReducer,
    propertyReducer,
    loanReducer,
    offerReducer,
});
