import axios from 'axios';
const { config } = require(`../config/env.${process.env.REACT_APP_ENVIRONMENT}`);

export const BASE_URL = config.BASE_URL;
export const API_URL = config.API_URL;

export const borrowerInstance = axios.create({
    baseURL: config.API_URL,
    headers: {
        'content-type': 'Application/json',
    },
});

export const AUTHORIZATION = 'authToken';
export const proxy = 'https://cors-anywhere.herokuapp.com/';
export const API: any = axios.create({
    baseURL: config.API_URL,
    // baseURL:'http://builder-dev.refirx.com:1117/api/v1/'
});

export const authorize = (token: string) => {
    if (token) {
        API.defaults.headers[AUTHORIZATION] = token;
    }
};

export const resetTokenAndLocalStorage = () => {
    delete API.defaults.headers[AUTHORIZATION];
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('auth');
};

export const setAuthrizationToken = () => {
    const token = localStorage.getItem('token');
    if (token) {
        authorize(JSON.parse(token));
    }
};
export const getToken = () => {
    const token = localStorage.getItem('token');
    if (token) {
        return JSON.parse(token);
    } else {
        return null;
    }
};
export const getEmail = () => {
    const email = localStorage.getItem('email');
    try {
        if (email && email !== 'null') {
            return JSON.parse(email);
        } else {
            return null;
        }
    } catch {
        return null;
    }
};

export const getUserInfo = () => {
    const userData = localStorage.getItem('auth');
    if (userData) {
        return JSON.parse(userData).responseObject;
    }
    return null;
};

setAuthrizationToken();
