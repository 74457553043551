import {
    GET_ALL_PROPERTIES,
    GET_DROPDOWN_LIST,
    CREATE_AUCTION_WITH_NEW_PROP,
    GET_AUCTION,
    GET_ALL_DOCUMENT,
    SINGLE_BID_INFO,
    LoanderSpinner,
    GET_ALL_AUCTIONS_LIST,
} from '../types';

const initialState = {
    AllAuction: null,
    AllDocuments: {},
    bidInfo: {},
    loader: false,
    propertyList: null,
};

function AuctionReducer(state = initialState, action: any) {
    switch (action.type) {
        case GET_AUCTION:
            return {
                ...state,
                data: action.payload,
            };

        case GET_ALL_PROPERTIES:
            return {
                ...state,
                propertyList: action.payload,
            };

        case LoanderSpinner:
            return {
                ...state,
                loader: action.payload,
            };

        case GET_DROPDOWN_LIST:
            return {
                ...state,
                auction: action.payload,
            };
        case CREATE_AUCTION_WITH_NEW_PROP:
            return {
                ...state,
                auction: action.payload,
            };
        case SINGLE_BID_INFO:
            return {
                ...state,
                bidInfo: action.payload,
            };

        case GET_ALL_AUCTIONS_LIST:
            return {
                ...state,
                AllAuction: action.payload,
            };

        case GET_ALL_DOCUMENT:
            return {
                ...state,
                AllDocuments: action.payload,
            };
        default:
            return state;
    }
}

export default AuctionReducer;
