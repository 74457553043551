export const getLocalStorageData = () => {
    const data = localStorage.getItem('auth');
    if (data) {
        return JSON.parse(data);
    } else {
        return null;
    }
};

export const removeLocalStorage = () => {
    const data = localStorage.clear();
};

export const getValueFromLocalStorage = (key: string) => {
    try {
        const data: any = localStorage.getItem(key);
        return JSON.parse(data);
    } catch {
        return null;
    }
};

export const setValueInLocalStorage = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
};

export const localStorageKeys = {
    propertyInfo: 'propertyInfo',
    appointmentSchedule: 'appointmentSchedule',
    noAuthRoute: 'noAuthRoute',
    propertyData: 'propertyData',
    propertyViewedCount: 'propertyViewedCount',
    deviceToken: 'deviceToken',
    isSalePropertyFlow: 'isSalePropertyFlow',
};
