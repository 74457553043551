import { actionInterface } from '../../Interfaces/redux/property';
import { propertyInitialState } from '../../Interfaces/redux/reducers';
import { GET_ALL_PROPERTIES } from '../types';

const initialState: propertyInitialState = {
    properties: null,
};

const propertyReducer = (state = initialState, action: actionInterface) => {
    switch (action.type) {
        case GET_ALL_PROPERTIES: {
            return { ...state, properties: action.payload };
        }
        default: {
            return { ...state };
        }
    }
};

export default propertyReducer;
