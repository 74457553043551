import { IconButton, makeStyles } from '@material-ui/core';
import React from 'react';

interface props{
    onClick?:()=>void;
    img:string
}

const useStyles = makeStyles({
    icon: {
        width: '1.5rem',
    },
});

const SocialButton: React.FC<props> = ({onClick, img}) => {
    const classes = useStyles();
    return (
        <IconButton onClick={onClick}>
        <img src={img} className={classes.icon} alt="facebook login button" />
    </IconButton>
    );
}

export default SocialButton;
