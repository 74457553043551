const initialState: any = {};
const cometChatReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "COMET_CHAT_GET_ALL_USERS": {
      return {
        ...state,
        allUsers: action.payload,
      };
    }

    case "COMET_CHAT_GET_ALL_GROUPS": {
      return {
        ...state,
        allGroups: action.payload,
      };
    }

    case "COMET_CHAT_REGISTER_USER": {
      return {
        ...state,
        allGroups: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
export default cometChatReducer;
