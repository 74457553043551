import { toast } from 'react-toastify';
import { loader } from './userActions';

export const handleError = (dispatch: any, err: any) => {
    console.log(err);
    if (err.response) {
        dispatch(loader(false));
        toast.error(err.response.data.message);
    } else {
        dispatch(loader(false));
        toast.error('Something Went Wrong!');
    }
};

export const handleRedirection = (err: any) => {
    if (err?.response?.status === 401) {
        toast.error('Session Expired. Please login again to continue...');
        localStorage.clear();
        window.location.replace('/');
    }
};
