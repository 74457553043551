import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Grid, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { colors } from '../../theme';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            borderRadius: '1rem',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(4),
            maxWidth: (props: { width: any }) => (props.width ? props.width : '40rem'),
            marginLeft: 'auto',
            marginRight: 'auto',
            border: 'none',
            '@media (max-width:500px)': {
                padding: '18px',
                maxWidth: '98%',
            },
            '&:focus': {
                outline: 'none',
            },
        },
        icon: {
            fontSize: '8rem',
            color: colors.primary,
        },
        title: {
            fontSize: '1.3rem',
            lineHeight: '2.2rem',
            color: colors.black,
            textAlign: 'center',
            fontWeight: 100,
        },
        closeIcon: {
            position: 'absolute',
            top: '1rem',
            right: '1rem',
            backgroundColor: colors.white,
            boxShadow: `0 0 15px 2px rgba(0,0,0,0.2)`,
            zIndex: 1,
            '& svg': {
                fontSize: '1rem',
                color: colors.primary,
            },
            '&:focus': {
                outline: 'none',
            },
            '&:hover': {
                backgroundColor: colors.white,
            },
        },
    })
);

interface props {
    msg?: string;
    handleClose?: () => void;
    openOrNot: boolean;
    children?: React.ReactNode;
    width?: string;
}

export const ModalComponent: React.FC<props> = ({ msg, handleClose, openOrNot, children, width }) => {
    const classes = useStyles({ width });

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openOrNot}
                // onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={openOrNot}>
                    <>
                        <Grid container alignItems="center" direction="column" justify="center" className={classes.paper}>
                            <IconButton className={classes.closeIcon} onClick={handleClose}>
                                <Close />
                            </IconButton>
                            {children}
                        </Grid>
                    </>
                </Fade>
            </Modal>
        </div>
    );
};

export default ModalComponent;
