export const endpoints = {
    socialRegister: 'register/social',
    socialLogin: 'login/social',
    filterDropdown: 'dropdown',
    profileInfo: 'user/personal/information',
    aadhar: 'validate/aadhaar',
    pan: '/validate/pan',
    deletePhoneNumber: '/user/userOtherPhone',
    verifyAadharOTP: '/verify/aadhaar/otp',
    verifyVoterOTP: '/verify/voter/id',
    document: '/document',
    userDocument: '/user/personal/document',
    cosigner: '/user/cosigner/accept',
    favorite: '/user/favorite',
    auction: '/auction',
    existingProperty: '/auction/existing',
    dropdown: '/dropdown',
    property: '/usernetworth/propertyList',
    selectBid: '/auction/selectbid',
    loanBid: '/loandocument/bid',
    dashboard: '/user/dashboard',
    usernetworth: '/usernetworth',
    liability: '/user/liability',
    userIncome: '/user/income',
    userDashboard: '/user/dashboard',
    otherIncome: '/user/income/otherincome',
    userEmployement: '/user/income/employement',
    autocomplete: '/user/autocomplete',
    otherIncomeSource: '/user/otherincome',
    bookAppointment: '/user/scheduled/appointment',
    appointmentSearch: '/user/scheduled/appointment/search',
    deviceToken: '/device/token',
    anonDeviceToken: '/anon/device/token',
    notification: '/notification',
    saleProperty: '/saleproperty/search',
    addOfferByUser: '/saleproperty/offer/user',
    offerByuserEmailId: '/saleproperty/offer/user/email',
    salePropertyBtId: '/user/saleproperty',
    anonSalePropertyBtId: '/anon/saleproperty',
    anonSaleProperty: '/anon/saleproperty/search',
    salePropertyBuilder: '/user/saleproperty/builders/with/cover',
    neighborhoodTally: '/user/saleproperty/neighborhood/tally',
    neighboardhoodTallyDetails: '/user/saleproperty/neighborhood/tally/detail',
    rentalProperty: '/rentalproperty/search',
    addRentalOfferByUser: '/rentalproperty/offer/user',
    offerRentalByuserEmailId: '/rentalproperty/offer/user/email',
    rentalPropertyBtId: '/user/rentalproperty',
    anonRentalPropertyBtId: '/anon/rentalproperty',
    anonRentalProperty: '/anon/rentalproperty/search',
    rentalPropertyBuilder: '/user/rentalproperty/builders/with/cover',
    rentalNeighborhoodTally: '/user/rentalproperty/neighborhood/tally',
    rentalNeighboardhoodTallyDetails: '/user/rentalproperty/neighborhood/tally/detail',
};
