import { BID_DOCUMENTS_LIST, LOAN_LIST, PROPERTY_LIST } from '../types/loanTypes';
import { GET_OFFERS_BY_USER_EMAIL } from '../types/offerTypes';

const initialState = {
    offers: [],
};

const offerReducer = (state = initialState, action: any) => {
    const newState = { ...state };
    switch (action.type) {
        case GET_OFFERS_BY_USER_EMAIL: {
            return { ...newState, offers: action.payload };
        }
        default: {
            return { ...newState };
        }
    }
};

export default offerReducer;
