import { initializeFirebase } from '../components/fbGoogleLogin/initializeFirebase';
import { pushNotifcation } from '../Helper/pushNotification';
import { addDeviceTokenAPIcall } from '../redux/actions/userActions';
import { getUserInfo } from '../utility/api';
import { localStorageKeys, setValueInLocalStorage } from '../utility/getLocalStorageData';
import  firebase from 'firebase/compat';
import isSupported = firebase.messaging.isSupported;

const deviceTokenAddOrUpdateAPIcall = (token: string, apiMethod: string) => {
    const userData = getUserInfo() || {};
    const data = {
        base64EncodedDeviceToken: btoa(token),
        hasSleekfinAccount: userData.primaryEmail ? true : false,
        lastBase64EncodedDeviceToken: btoa(token),
        ownerEmail: userData.primaryEmail || null,
        ownerPhone: userData.phoneNo || null,
        ownerRole: userData.primaryEmail ? 'consumer' : null,
        userId: userData.id || null,
    };

    addDeviceTokenAPIcall(data, apiMethod, Object.keys(userData).length === 0 ? true : false);
};

export const usePushNotification = async () => {

    try {
        const isSupportedBrowser = await isSupported();
        if (!isSupportedBrowser) {
            console.log('Firebase not supported this browser');
            return null;
        }
    } catch (err) {
        console.log(err);
        return null;
    }

    initializeFirebase();
    if (window.location.href === '/' && !getUserInfo()) {
        localStorage.removeItem('auth');
    }

    const messaging = (window as any).firebase.messaging();

    const makeNotificationRequest = () => {
        messaging
            .getToken({ vapidKey: 'BNEGTbYC3czoxdMumkDRY9mxfBLgfwx2M3w7sYIeugT5vNXIajYXWwgICG_xR4oH0EX1pt_Oo5jvBp3MBke-RIc' })
            .then((token: any) => {
                setValueInLocalStorage(localStorageKeys.deviceToken, token);
                deviceTokenAddOrUpdateAPIcall(token, 'post');
            });
        messaging.onTokenRefresh((token: any) => {
            setValueInLocalStorage(localStorageKeys.deviceToken, token);
            deviceTokenAddOrUpdateAPIcall(token, 'put');
        });
        const onMessageListener = () =>
            new Promise((resolve) => {
                messaging.onMessage((payload: any) => {
                    resolve(payload);
                });
            });
        onMessageListener()
            .then((payload: any) => {
                pushNotifcation(payload);
            })
            .catch((err) => console.log('failed: ', err));
    };

    if (!('Notification' in window)) {
        // Check if the browser supports notifications
        console.log('This browser does not support desktop notification');
    } else if (Notification.permission === 'granted') {
        makeNotificationRequest();
    } else if (Notification.permission !== 'denied') {
        // We need to ask the user for permission
        Notification.requestPermission().then((permission) => {
            // If the user accepts, let's create a notification
            if (permission === 'granted') {
                makeNotificationRequest();
            } else if (permission === 'denied') {
                const userData = getUserInfo() || {};

                const data = {
                    base64EncodedDeviceToken: 'User declined push notification',
                    hasSleekfinAccount: userData.primaryEmail ? true : false,
                    lastBase64EncodedDeviceToken: 'User declined push notification',
                    ownerEmail: userData.primaryEmail || null,
                    ownerPhone: userData.phoneNo || null,
                    ownerRole: userData.primaryEmail ? 'consumer' : null,
                    userId: userData.id || null,
                };

                addDeviceTokenAPIcall(data, 'post', Object.keys(userData).length === 0 ? true : false);
            }
        });
    }
};
