import {
    AUTHENTICATION,
    GOOGLE_LOGIN,
    BORROWER_LOGIN,
    LOG_OUT,
    LANG,
    USER_REGISTER,
    SPINNER,
    MODAL,
    LOGIN_FAILED,
    LENDER_REGISTRATION,
    LENDER_LOGIN,
    REGISTRATION_FAILED,
    PROFILE_AVATAR,
    EMAIL_VERIFIED,
    PROFILE_UPLOAD,
    IS_FAILED,
    GET_EMAIL,
    USER_PROFILE_INFO,
    IS_SALE_PROPERTY_FLOW,
} from '../types/userTypes';
import { API, borrowerInstance, setAuthrizationToken } from '../../utility/api';
import endPoint from '../../utility/urlEndPoints';
import { store } from '../store';
import { borrowerBaseUrl } from '../../utility/baseUrls';
import Axios from 'axios';
import { geturl, getProfile, getProfilePersonalInfo, getConsumerData } from './profileAction';
import { LOADER } from '../types';
import { toast } from 'react-toastify';
import { handleError, handleRedirection } from './errorHandler';
import { endpoints } from '../../utility/endpoints';
import { Toast } from 'react-toastify/dist/components';
import { getValueFromLocalStorage, localStorageKeys } from '../../utility/getLocalStorageData';

const dispatch = store.dispatch;
const DASHBOARD_URL = '/user/dashboard';

export function isAuthenticated(value: any) {
    return {
        type: AUTHENTICATION,
        payload: value,
    };
}

export function getEmail(email: string) {
    return {
        type: GET_EMAIL,
        payload: email,
    };
}

export function logginWithGoogle(data: any) {
    return {
        type: GOOGLE_LOGIN,
        payload: data,
    };
}

export function borrowerLogin(data: any) {
    return {
        type: BORROWER_LOGIN,
        payload: data,
    };
}

export function lenderLoginRes(data: any) {
    return {
        type: LENDER_LOGIN,
        payload: data,
    };
}

export function isLoadingShow(value: boolean) {
    return {
        type: SPINNER,
        payload: value,
    };
}

export function isModalShow(value: boolean) {
    return {
        type: MODAL,
        payload: value,
    };
}

export function isLoginFaield(value: boolean) {
    return {
        type: LOGIN_FAILED,
        payload: value,
    };
}

export function isRegistrationFailed(value: boolean) {
    return {
        type: REGISTRATION_FAILED,
        payload: value,
    };
}

export function isEmailVerFailed(value: boolean) {
    return {
        type: EMAIL_VERIFIED,
        payload: value,
    };
}

export function loginWithEmailPass(data: any, history: any, pathname: string, isAnonymous: boolean, hideModal: () => void) {
    dispatch(isLoadingShow(true));
    dispatch(loader(true));
    borrowerInstance
        .post(endPoint.login, data)
        .then(async (res) => {
            await localStorage.setItem('token', JSON.stringify(res.data.token));
            await localStorage.setItem('email', JSON.stringify(res.data.responseObject.primaryEmail));
            await localStorage.setItem('auth', JSON.stringify(res.data));
            await setAuthrizationToken();
            await dispatch(loader(false));
            await dispatch(getEmail(data.email));
            await dispatch(isLoadingShow(false));
            await dispatch(borrowerLogin(res.data));
            await dispatch(getConsumerData());
            await dispatch(getProfilePersonalInfo());
            if (!isAnonymous) {
                history.push(pathname);
            } else {
                history.push({
                    pathname: getValueFromLocalStorage(localStorageKeys.noAuthRoute) || '/',
                    state: { propertyData: getValueFromLocalStorage(localStorageKeys.propertyData) },
                });
                window.location.reload();
            }
            hideModal && hideModal();
        })
        .catch((err) => {
            dispatch(isLoadingShow(false));
            dispatch(isLoginFaield(true));
            dispatch(isAuthenticated(false));
            handleError(dispatch, err);
            if (!isAnonymous) {
                handleRedirection(err);
            }
        });
}

export async function loginWithMobilenumber(phonenumber: any) {
    const result = await borrowerInstance.get(`${endPoint.sendotp}/${phonenumber}`);
    if ([200, 2001].includes(result.status)) {
        toast.success(result.data.message);
    } else {
        toast.error(result.data.message);
    }
    handleRedirection(result);
    return result;
}

export async function loginWithMobileOTP(sessionid: any, otp: string, history: any, pathname: string) {
    dispatch(loader(true));
    borrowerInstance
        .get(`${endPoint.loginotp}/${sessionid}/${otp}`)
        .then(async (res) => {
            await localStorage.setItem('token', JSON.stringify(res.data.token));
            await localStorage.setItem('email', JSON.stringify(res.data.responseObject.primaryEmail));
            await localStorage.setItem('auth', JSON.stringify(res.data));
            await setAuthrizationToken();
            await dispatch(loader(false));
            await dispatch(borrowerLogin(res.data));
            await dispatch(getConsumerData());
            await dispatch(getProfilePersonalInfo());
            toast.success(res.data.message);
            history.push(pathname);
        })
        .catch((err) => {
            dispatch(isLoadingShow(false));
            dispatch(isLoginFaield(true));
            dispatch(isAuthenticated(false));
            handleError(dispatch, err);
            handleRedirection(err);
        });
}

export async function validateOTP(sessionid: any, otp: string, email: string) {
    return API.get(`${endPoint.loginotp}/${sessionid}/${otp}?userEmail=${email}`).then(async (res: any) => {
        toast.success(res.data.message);
        return res;
    });
}

export function lenderLogin(data: any) {
    dispatch(isLoadingShow(true));
    borrowerInstance
        .post(endPoint.login, data)
        .then((res) => {
            if (res.data.responseObject.agentId) {
                dispatch(getEmail(data.email));
                dispatch(isLoadingShow(false));
                dispatch(lenderLoginRes(res.data));
            } else {
                setTimeout(() => alert('You are not authorized user.'), 100);
                dispatch(isLoadingShow(false));
                dispatch(isAuthenticated(false));
            }
        })
        .catch((err) => {
            dispatch(isLoadingShow(false));
            dispatch(isLoginFaield(true));
            dispatch(isAuthenticated(false));
            handleRedirection(err);
        });
}

export function logoutNreset() {
    return {
        type: LOG_OUT,
        payload: 'persist:root',
    };
}

export function checkAuthentication() {
    const res = geturl()
        .get(endPoint.profile)
        .then((res) => console.log(res))
        .catch((err) => {
            dispatch(isAuthenticated(false));
            handleRedirection(err);
        });
    return {
        type: LOG_OUT,
        payload: res,
    };
}

export function logOut(token?: string) {
    // const logout = axios.create({
    //   baseURL: borrowerBaseUrl,
    //   headers: {
    //     authToken: token
    //   }
    // });
    geturl()
        .delete(endPoint.logout, {})
        .then((res) => {
            dispatch(logoutNreset());
            window.location.href = '/';
        })
        .catch((err) => (window.location.href = '/'));
    return {
        type: 'lout',
        payload: '',
    };
}

export function chooseLang(lang: string) {
    return {
        type: LANG,
        payload: lang,
    };
}

export function userRegistration(data: object) {
    return {
        type: USER_REGISTER,
        payload: data,
    };
}

export function lenderRegistration(data: object) {
    return {
        type: LENDER_REGISTRATION,
        payload: data,
    };
}

export function userRegister(data: object, callback: () => void) {
    dispatch(isLoadingShow(true));
    dispatch(loader(true));
    const res = borrowerInstance
        .post(endPoint.register, data)
        .then((res) => {
            dispatch(loader(false));
            dispatch(userRegistration(res.data));
            dispatch(isLoadingShow(false));
            callback();
            toast.success('Congratulation your account has been successfully created. please verify your email.');
        })
        .catch((err) => {
            dispatch(isRegistrationFailed(true));
            dispatch(isLoadingShow(false));
            console.log(err);
        });
}

export function lenderRegister(data: object, licence: string) {
    dispatch(isLoadingShow(true));
    const res = borrowerInstance
        .post(`${endPoint.register}?licence=${licence}`, data)
        .then((res) => {
            dispatch(isLoadingShow(false));
            dispatch(isModalShow(true));
            dispatch(lenderRegistration(res.data));
        })
        .catch((err) => {
            dispatch(isRegistrationFailed(true));
            dispatch(isLoadingShow(false));
            dispatch(isLoginFaield(true));
            console.log(err);
        });
}

export function profileAvatar(path: string) {
    return {
        type: PROFILE_AVATAR,
        payload: path,
    };
}

export function porfileUploadRes(data: any) {
    return {
        type: PROFILE_UPLOAD,
        payload: data,
    };
}

export function uploadAvatar(data: any) {
    console.log(data);
    geturl()
        .post(`${borrowerBaseUrl}/uploadimage`, data, {})
        .then((res) => {
            if (res.data.status === 200) {
                dispatch(porfileUploadRes(res.data));
                getProfile();
                setTimeout(() => alert('Successfully Uploaded profile avatar'), 100);
            }
        })
        .catch((err) => {});
}

export async function emailVerificationBorrower(token: string) {
    dispatch(isLoadingShow(true));
    const result = await API.get(endPoint.verify, { params: { token } })
        .then((res: any) => {
            dispatch(isModalShow(true));
            dispatch(isLoadingShow(false));
            return res;
        })
        .catch((err: any) => {
            dispatch(isEmailVerFailed(true));
            dispatch(isLoadingShow(false));
            return err;
        });
    return result;
}

export function setIsFailed(data: boolean) {
    return {
        type: IS_FAILED,
        payload: data,
    };
}

export function forgotPassword(data: any) {
    dispatch(isLoadingShow(true));
    const url = Axios.create({
        baseURL: borrowerBaseUrl,
        headers: {
            'content-type': 'Application/json',
        },
    });
    url.post(`${endPoint.forgotPass}?email=${data.email}`)
        .then((res) => {
            console.log(res);
            dispatch(isModalShow(true));
            dispatch(isLoadingShow(false));
        })
        .catch((err) => {
            dispatch(setIsFailed(true));
            dispatch(isLoadingShow(false));
        });
}

export function resetPassword(data: any, token: any) {
    dispatch(isLoadingShow(true));
    const url = Axios.create({
        baseURL: borrowerBaseUrl,
        headers: {
            'content-type': 'Application/json',
        },
    });
    url.post(`${endPoint.resetPass}?token=${token}`, data)
        .then((res) => {
            console.log(res);
            dispatch(isModalShow(true));
            dispatch(isLoadingShow(false));
        })
        .catch((err) => {
            dispatch(setIsFailed(true));
            dispatch(isLoadingShow(false));
        });
}

export function resetAllDataResonse401(err: any) {
    const { accessToken } = store.getState().userReducer;
    if (err.response.status === 401) {
        logOut(accessToken);
        dispatch(logoutNreset());
        // logOut(accessToken);
        // dispatch(logoutNreset())
    }
}

/* loader */
export const loader = (data: boolean) => {
    return {
        type: LOADER,
        payload: data,
    };
};

export const getUserProfileInfoAPIcall = () => {
    dispatch(loader(true));
    API.get(endpoints.profileInfo)
        .then((res: { data: { responseObject: any } }) => {
            dispatch(loader(false));
            dispatch(getUserProfileInfoRes(res.data.responseObject));
        })
        .catch((err: any) => {
            dispatch(loader(false));
            handleRedirection(err);
        });
};

export const getUserProfileInfoRes = (data: any) => {
    return {
        type: USER_PROFILE_INFO,
        payload: data,
    };
};

export const updateUserProfileInfoAPIcall = (data: any) => {
    dispatch(loader(true));
    API.put(endpoints.profileInfo, data)
        .then((res: any) => {
            toast.success('Your profile updated successfully.');
            dispatch(loader(false));
            getUserProfileInfoAPIcall();
        })
        .catch((err: any) => {
            dispatch(loader(false));
            handleError(dispatch, err);
            handleRedirection(err);
        });
};

export const aadharValidateAPIcall = async (data: any) => {
    dispatch(loader(true));
    const result = await API.post(endpoints.aadhar, data)
        .then((res: any) => {
            dispatch(loader(false));
            return res;
        })
        .catch((err: any) => {
            dispatch(loader(false));
            handleError(dispatch, err);
            handleRedirection(err);
            return err;
        });

    return result;
};

export const panCardValidateAPIcall = async (data: any) => {
    dispatch(loader(true));
    const result = await API.post(endpoints.pan, data)
        .then((res: any) => {
            dispatch(loader(false));
            return res;
        })
        .catch((err: any) => {
            dispatch(loader(false));
            handleError(dispatch, err);
            handleRedirection(err);
            return err;
        });
    return result;
};

export const deletePhoneNumberAPIcall = async (id: any) => {
    dispatch(loader(true));
    const result = await API.delete(endpoints.deletePhoneNumber + `/${id}`)
        .then((res: any) => {
            toast.success('Phone number deleted successfully.');
            dispatch(loader(false));
            return res;
        })
        .catch((err: any) => {
            dispatch(loader(false));
            handleError(dispatch, err);
            handleRedirection(err);
            return err;
        });
    return result;
};

export const verifyAadharOTPAPIcall = async (data: any) => {
    dispatch(loader(true));
    const result = await API.post(endpoints.verifyAadharOTP, data)
        .then((res: any) => {
            toast.success('Aadhar number verified successfully.');
            dispatch(loader(false));
            return res;
        })
        .catch((err: any) => {
            dispatch(loader(false));
            handleError(dispatch, err);
            handleRedirection(err);
            return err;
        });
    return result;
};

export const voterVerificationAPIcall = async (data: any) => {
    dispatch(loader(true));
    const result = await API.post(endpoints.verifyVoterOTP, data)
        .then((res: any) => {
            toast.success('Voter ID verified successfully.');
            dispatch(loader(false));
            return res;
        })
        .catch((err: any) => {
            dispatch(loader(false));
            handleError(dispatch, err);
            handleRedirection(err);
            return err;
        });
    return result;
};

export const uploadDocumentAPIcall = async (document: any) => {
    dispatch(loader(true));
    const data = new FormData();
    data.append('file', document, document.name);
    const result = await API.post(endpoints.document, data)
        .then((res: any) => {
            dispatch(loader(false));
            return res;
        })
        .catch((err: any) => {
            dispatch(loader(false));
            handleError(dispatch, err);
            handleRedirection(err);
            return err;
        });
    return result;
};

export const userDocumentAddAPIcall = async (data: any) => {
    dispatch(loader(true));
    const result = await API.post(endpoints.userDocument, data)
        .then((res: any) => {
            toast.success('Document uploaded successfully.');
            dispatch(loader(false));
            return res;
        })
        .catch((err: any) => {
            dispatch(loader(false));
            handleError(dispatch, err);
            handleRedirection(err);
            return err;
        });
    return result;
};

export const userDeleteDocumentAPIcall = async (documentKey: string) => {
    dispatch(loader(true));
    const result = await API.delete(endpoints.userDocument + `/${documentKey}`)
        .then((res: any) => {
            toast.success('Document deleted successfully.');
            getUserProfileInfoAPIcall();
            dispatch(loader(false));
            return res;
        })
        .catch((err: any) => {
            dispatch(loader(false));
            handleError(dispatch, err);
            handleRedirection(err);
            return err;
        });
    return result;
};

export const cosignerEmailVerification = async (token: string) => {
    dispatch(loader(true));
    // dispatch(isLoadingShow(true));
    const result = await API.put(endpoints.cosigner + `?token=${token}`)
        .then((res: any) => {
            dispatch(isModalShow(false));
            dispatch(isEmailVerFailed(false));
            dispatch(loader(false));
            return res;
        })
        .catch((err: any) => {
            dispatch(isEmailVerFailed(false));
            // dispatch(isLoadingShow(false));
            dispatch(loader(false));
            handleRedirection(err);
            return err;
        });
    return result;
};

export const dashboardScenarioData = async (data?: any) => ({
    type: 'DASHBOARD_SCENERIO',
    payload: data,
});

export const getDashboardScenarioData = async (data?: any) => {
    return async (dispatch: any, getStates: any) => {
        dispatch(loader(true));
        const result = await API.get(endpoints.dashboard)
            .then(async (res: any) => {
                dispatch(loader(false));
                await dispatch(getConsumerData());
                const ind = res?.data?.responseObject.findIndex((a: any) => a.scenarioName === 'DASHBOARD');
                if (!res?.data?.responseObject || ind < 0) return dispatch(dashboardScenarioData(null));
                const _data = res?.data?.responseObject[ind >= 0 ? ind : 0];
                dispatch(dashboardScenarioData(_data));
                return res;
            })
            .catch((err: any) => {
                dispatch(loader(false));
                handleRedirection(err);
                return err;
            });

        return result;
    };
};

export const postDashboardScenarioData = async ({ id, data }: { id?: number; data: any }) => {
    return async (dispatch: any, getStates: any) => {
        const scenarioData = { scenarioName: 'DASHBOARD', scenarioJson: JSON.stringify(data) };
        dispatch(loader(true));
        let result = null;

        if (!id) {
            await API.post(endpoints.dashboard, [scenarioData])
                .then(async (res: any) => {
                    dispatch(loader(false));
                    await dispatch(getDashboardScenarioData());
                    return res;
                })
                .catch((err: any) => {
                    dispatch(loader(false));
                    handleRedirection(err);
                    return err;
                });
        } else {
            await API.put(`${endpoints.dashboard}/${id}`, scenarioData)
                .then(async (res: any) => {
                    dispatch(loader(false));
                    if (res.data.status !== 200) alert('Data not updated. Please try again!');
                    return res;
                })
                .catch((err: any) => {
                    dispatch(loader(false));
                    handleRedirection(err);
                    return err;
                });
        }

        return result;
    };
};

export const addDeviceTokenAPIcall = (data: any, apiMethod: string, isAnonymous: boolean) => {
    let path = isAnonymous ? endpoints.anonDeviceToken : endpoints.deviceToken;
    API[apiMethod](path, data)
        .then((res: any) => {
            console.log(res);
        })
        .catch((err: any) => {
            console.log(err);
        });
};

export const getNotificationAPIcall = async (params: any) => {
    const result = await API.get(endpoints.notification, { params });

    if (result.status === 200) {
        return result;
    }
};

export const setIsSalePropertyFlow = (value: boolean) => {
    return {
        type: IS_SALE_PROPERTY_FLOW,
        payload: value,
    };
};
