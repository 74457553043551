import { faFacebookF, faInstagram, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import Tile from './Tile';
import { Link } from 'react-router-dom';
import { colors } from '../../theme';

const useStyles = makeStyles({
    parent: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '80.125rem',
        flexWrap: 'nowrap',
        paddingTop: '1rem',
        paddingBottom: '1rem',
        borderTop: `1px solid ${colors.darkGray}`,
        '@media (max-width: 500px)': {
            flexWrap: 'wrap',
        },
    },
    container: {
        width: '25rem',
        '@media (max-width: 500px)': {
            width: '100%',
            marginTop: '1rem',
        },
    },
    title: {
        fontSize: '1.1rem',
        color: colors.darkGray,
        cursor: 'pointer',
        '&:hover': {
            color: colors.primary,
        },
        '@media (max-width: 500px)': {
            width: '100%',
            textAlign: 'center',
            marginTop: '1rem',
        },
    },
    tc: {
        color: colors.darkGray,
        '&:hover': {
            color: colors.primary,
        },
    },
    tcsmall: {
        fontSize: '0.75rem',
        color: colors.darkGray,
    },
});

const Footer = () => {
    const classes = useStyles();

    return (
        <Grid container alignItems="center" justify="space-between" className={classes.parent}>
            <Grid>
                <Typography className={classes.title}>© 2020 Sleekfin India P Ltd</Typography>
                <Typography className={classes.tcsmall}>Room 231, Workafella Business Centre, 6-3-252/2 Sriram Nest Mega City No. 716</Typography>
                <Typography className={classes.tcsmall}>Irrummanzil, Somajiguda, Hyderabad 500082</Typography>
                <Link to="/contact-us" className={classes.tc}>
                    Contact Us
                </Link>
            </Grid>
            <Grid container alignItems="center" justify="center" className={classes.container}>
                <Tile icon={faTwitter} link="https://twitter.com/sleek_fin" />
                <Tile icon={faLinkedinIn} link="https://www.linkedin.com/company/sleekfin" />
                <Tile icon={faInstagram} link="https://www.instagram.com/sleekfin/" />
                <Tile icon={faFacebookF} link="https://www.facebook.com/sleekfin" />
                {/* <Tile icon={icons.youtube} link="https://www.instagram.com/sleekfin/" /> */}
            </Grid>
            <Typography className={classes.title}>
                <a href="mailto:info@sleekfin.com" className={classes.tc}>
                    contact: info@sleekfin.com
                </a>
            </Typography>
            <Typography className={classes.tc}>Phone: +91 40674 25501</Typography>
        </Grid>
    );
};

export default Footer;
