export const GET_ALL_PROPERTIES = "GET_ALL_PROPERTIES";
export const GET_DROPDOWN_LIST = "GET_DROPDOWN";
export const CREATE_AUCTION_WITH_NEW_PROP = "CREATE_AUCTION_WITH_NEW_PROP";
export const CREATE_AUCTION_WITH_EXISTING_PROP =
  "CREATE_AUCTION_WITH_EXISTING_PROP";
export const SINGLE_BID_INFO = "SINGLE_BID_INFO";

export const GET_ALL_AUCTIONS_LIST = "GET_ALL_AUCTIONS_LIST";
export const GET_AUCTION = "GET_AUCTION";

export const GET_ALL_DOCUMENT = "GET_ALL_DOCUMENTS";
export const UPLOAD_DOCUMENT = "UPLOAD_ANY_DOCUMENT";
export const LoanderSpinner = "LoanderSpinner";


export const INVITE_COSIGNER = "INVITE_COSIGNER";
export const ACCEPT_COSIGNER = "ACCEPT_COSIGNER";
export const CHECK_COSIGNER = "CHECK_COSIGNER";
export const DELETE_COSIGNER = "DELETE_COSIGNER";
