import { Button } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import Loader from '../UI/Loader';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../theme';
import classnames from 'classnames';

const useStyles = makeStyles({
    button: {
        width: '100%',
        backgroundColor: colors.primary,
        color: colors.white,
        textTransform: 'capitalize',
        fontWeight: 300,
        '&:hover': {
            backgroundColor: colors.primary,
        },
        '&:focus': {
            outline: 'unset',
        },
        '&:disabled': {
            opacity: 0.6,
            color: colors.white,
        },
    },
});

interface props {
    text: any;
    onClick?: (e: any) => void;
    type?: any;
    externalClass?: string;
    hideLoader?: boolean;
    externalClassName?: string;
    disableLoader?: boolean;
    disableButton?: boolean;
}

const NormalButton: React.FC<props> = ({ text, type, externalClassName, onClick, disableLoader, disableButton }) => {
    const classes = useStyles();
    const loader = useSelector((state: any) => state.uiReducer.loader);
    return (
        <Button type={type} disabled={loader ? loader : disableButton} className={classnames(classes.button, externalClassName)} onClick={onClick}>
            {disableLoader ? text : <>{loader ? <Loader /> : text}</>}
        </Button>
    );
};

export default NormalButton;
