export const pushNotifcation = (payload: any) => {
    console.log(Notification);
    if (Notification.permission !== 'granted') {
        Notification.requestPermission();
    } else {
        console.log(payload);
        var notification = new Notification(payload.notification.title, {
            icon: window.location.origin + '/logo.png',
            body: payload.notification.body,
        });
        console.log(payload);
        notification.onclick = function (e) {
            e.preventDefault();
            let url = window.location.origin;

            switch (String(payload.data.entityType)) {
                case 'appointment':
                    url = url + '/dlta/offer?offerId=' + payload.data.entityId;
                    break;
                case 'offer':
                    url = url + '/dlta/offer?offerId=' + payload.data.entityId;
                    break;
                case 'bid':
                    url = url + '/dlta/bid?bidId=' + payload.data.entityId;
                    break;
                case 'loanRequest':
                    url = url + '/dlta/loanrequest?loanRequestId' + payload.data.entityId;
                    break;
                case 'saleProperty':
                    url = url + '/dlta/saleproperty?salepropertyId=' + payload.data.entityId;
                    console.log(url);
                    break;
            }

            window.open(url, 'new_tab');
        };
        setTimeout(() => {
            notification.close();
        }, 10000);
    }
};
