import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import loaderImg from '../theme/assets/images/logo.png';
import { loaderInterface } from '../Interfaces/loader';
import classNames from 'classnames';
import { loaderSelector } from '../redux/selector/uiSelector';

const useStyles = makeStyles({
    msgWrapper: {
        marginTop: '3rem',
    },
    images: {
        fontSize: '2rem',
        fontWeight: 600,
    },
    msg: {
        fontSize: '1.3rem',
        fontWeight: 500,
    },
    conainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        height: '100vh',
        position: 'fixed',
        top: 0,
        zIndex: 1302,
        '& img': {
            width: '3rem',
            animation: 'zoomInOut 1s infinite',
            filter: `drop-shadow(0 0 10px #007aff63)`,
        },
    },
});

const InitialLoader: FC<loaderInterface> = ({ initial = false }) => {
    const classes = useStyles();
    const loader = useSelector(loaderSelector);

    if (initial || loader) {
        return (
            <Box className={classNames(classes.conainer, 'blurbg')}>
                <img src={loaderImg} alt="" />
            </Box>
        );
    } else {
        return <></>;
    }
};

export default InitialLoader;
