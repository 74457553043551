import { GET_PREAPPROVAL_DATA } from '../types'

const initialState = {
    firstName:{},
}

function PreApprovalReducer (state = initialState, action: any){
       switch (action.type) {
        case GET_PREAPPROVAL_DATA:
          return {
            ...state,
            personalInformation: action.payload
          };    
        default:
          return state;
      }
    
}

export default PreApprovalReducer;