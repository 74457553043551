export const CS_NAME_DATA = "CS_NAME_DATA";
export const CS_DOB = "CS_DOB";
export const CS_SSN_DATA = "CS_SSN_DATA";
export const CS_ADDRESS_DATA = "CS_ADDRESS_DATA";
export const CS_PHONE_DATA = "CS_PHONE_DATA";
export const CS_MARITAL_STATUS = "CS_MARITAL_STATUS";
export const CS_DEPENDENTS = "CS_DEPENDENTS";
export const CS_SPOUCE_SERVICE = "CS_SPOUCE_SERVICE";
export const CS_LEGAL_STATUS = "CS_LEGAL_STATUS";
export const CS_SEX = "CS_SEX";
export const CS_ETHNICITY = "CS_ETHNICITY";
export const CS_RACE = "CS_RACE";

export const SAVE_PERSONAL_DATA = "SAVE_PERSONAL_DATA"
