
import { GET_ALL_LOAN_REQUEST, GET_ALL_CURRENT_LOAN, GET_ALL_LOST_BIDS, GET_CURRENT_LOAN_INFO, GET_CURR_LOST_BID_INFO, GET_CLOSED_LOAN, GET_CLOSED_LOAN_INFO } from "../types/dashboardType";

const initialState = {
  totalActiveLoan: [],
  totalCurrentLoan: {},
  totalLostBids: {},
  currentLoanInfo: {},
  currLostBidInfo: {},
  closedLoan: {},
  closedLoanInfo:{}
}

function dashboardReducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_ALL_LOAN_REQUEST:
      return {
        ...state,
        totalActiveLoan: action.payload
      }
    case GET_ALL_CURRENT_LOAN:
      return {
        ...state,
        totalCurrentLoan: action.payload
      }
    case GET_ALL_LOST_BIDS:
      return {
        ...state,
        totalLostBids: action.payload
      }
    case GET_CURRENT_LOAN_INFO:
      return {
        ...state,
        currentLoanInfo: action.payload
      }
    case GET_CURR_LOST_BID_INFO:
      return {
        ...state,
          currLostBidInfo: action.payload
      }
    case GET_CLOSED_LOAN:
      return {
        ...state,
        closedLoan: action.payload
      }
    case GET_CLOSED_LOAN_INFO:
      return {
        ...state,
        closedLoanInfo: action.payload
      }
    default:
      return state;
  }
}

export default dashboardReducer;


