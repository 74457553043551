import {
  GET_LIABILITY_DATA,
  INSERT_LIABILITY_DATA,
  UPDATE_LIABILITY_DATA,
  DETELE_LIABILITY_DATA,
  GET_LIABILITY_CARD_DATA,
  DATA_LOADED
} from "../types";

const initialState = {
  totalActiveLoan: {},
  showLoader: false
};

function LiabilityReducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_LIABILITY_DATA:
      return {
        ...state,
        getLiabilityData: action.payload
      };

    case DATA_LOADED:
      return {
        ...state,
        showLoader: action.payload
      };

    case GET_LIABILITY_CARD_DATA:
      return {
        ...state,
        liabilityCardData: action.payload
      };

    case INSERT_LIABILITY_DATA:
      return {
        ...state,
        insertLiabilityData: action.payload
      };

    case UPDATE_LIABILITY_DATA:
      return {
        ...state,
        updateLiabilityData: action.payload
      };
    case DETELE_LIABILITY_DATA:
      return {
        ...state,
        deleteLiabilityData: action.payload
      };

    default:
      return state;
  }
}

export default LiabilityReducer;
