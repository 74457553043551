import googleIcon from '../theme/assets/Icon/google.png';
import linkedInIcon from '../theme/assets/Icon/linkedin.png';
import fbIcon from '../theme/assets/Icon/fb.png';
import facebookLogin from './assets/Icon/facebookLogin.svg';
import googleLogin from './assets/Icon/googleLogin.svg';
import mobileIcon from './assets/Icon/mobile@3x.png';

const Icons = {
    google: googleIcon,
    linkedIn: linkedInIcon,
    fb: fbIcon,
    facebookLogin,
    googleLogin,
    mobileIcon,
};

export default Icons;
