const endPoint = {
    login: '/login',
    register: '/register',
    logout: '/logout',
    updatePro: '/update/profile',
    profile: '/user/profile',
    changePass: '/change/password',
    verify: '/verify',
    loanReq: '/loan/request',
    bid: '/bid',
    currLoan: '/bid/active',
    forgotPass: '/consumer/forgot/password',
    resetPass: '/consumer/reset/password',
    getState: '/states',
    personalInfo: '/user/personal/information',
    dashboard: '/user/dashboard',
    sendotp: '/sendotp',
    loginotp: 'login/otp',
};

export default endPoint;
