import { CS_NAME_DATA, CS_DOB, CS_SSN_DATA, CS_PHONE_DATA, CS_MARITAL_STATUS, CS_DEPENDENTS, CS_SPOUCE_SERVICE, CS_LEGAL_STATUS, CS_SEX, CS_ETHNICITY, CS_RACE, CS_ADDRESS_DATA, SAVE_PERSONAL_DATA } from "../types/creditScoreType"



const initialState = {
  csNameData: {},
  csDobData: {},
  csSsnData: {},
  csAddressData: {},
  csPhoneData: {},
  csMaritalData: {},
  csDependData: {},
  csSpouceData: {},
  csLegalData: {},
  csSexData: {},
  csEthnicityData: {},
  csRaceData: {},
  personalInfoData:{}
}

function creditScoreReducer(state = initialState, action: any) {
  switch (action.type) {
    case CS_NAME_DATA:
      return {
        ...state,
        csNameData: action.payload
      }
    case CS_DOB:
      return {
        ...state,
        csDobData: action.payload
      }
    case CS_SSN_DATA:
      return {
        ...state,
        csSsnData: action.payload
      }
      case CS_ADDRESS_DATA:
        return {
          ...state,
          csAddressData: action.payload
        }
    case CS_PHONE_DATA:
      return {
        ...state,
        csPhoneData: action.payload
      }
    case CS_MARITAL_STATUS:
      return {
        ...state,
        csMaritalData: action.payload
      }
    case CS_DEPENDENTS:
      return {
        ...state,
        csDependData: action.payload
      }
    case CS_SPOUCE_SERVICE:
      return {
        ...state,
        csSpouceData: action.payload
      }
    case CS_LEGAL_STATUS:
      return {
        ...state,
        csLegalData: action.payload
      }
    case CS_SEX:
      return {
        ...state,
        csSexData: action.payload
      }
    case CS_ETHNICITY:
      return {
        ...state,
        csEthnicityData: action.payload
      }
    case CS_RACE:
      return {
        ...state,
        csRaceData: action.payload
      }
    case SAVE_PERSONAL_DATA:
      return {
        ...state,
        personalInfoData: action.payload
      }
    default:
      return state;
  }
}

export default creditScoreReducer;


