export const routingEndPoints = {
    borrLogin: '/Borrower',
    lender: '/Lender',
    borrowerSignUp: '/signup',
    lenderSignUpForm: '/Lender-sign-up',
    profile: '/dashboard/Profile',
    dashboard: '/dashboard/',
    contact: '/Contact',
    newsroom: '/Newsroom',
    plan: '/Plan',
    faq: '/FAQ',
    roadmap: '/Roadmap',
    forgotPass: '/forgot-password',
    resetPass: '/reset-password',
    finance: '/dashboard/finance',
    financeEdit: '/dashboard/finance-edit',
    auction: '/dashboard/auction',
    loans: '/dashboard/loans/:id?',
    netWorth: '/dashboard/networth',
    personalinfo: '/dashboard/personalinfo',
    preapproval: '/dashboard/properties',
    income: '/dashboard/income',
    liability: '/dashboard/liability',
    chat: '/dashboard/chatting',
    changeEmail: '/dashboard/add-email',
    changePassword: '/dashboard/change-password',
    loan: '/dashboard/loans/',
    loanEdit: '/dashboard/loans/edit',
    loanDetails: '/dashboard/loans/details',
    loanBids: '/dashboard/loans/bids',
    offers: '/dashboard/offers',
    dlta: '/dlta/',
    login: '/login',
    anonDefaultPath: '/',
    contactUs: '/contact-us',
    purchaseProperty: '/purchase-property',
};
