import { getValueFromLocalStorage, localStorageKeys } from '../../utility/getLocalStorageData';
import {
    AUTHENTICATION,
    GOOGLE_LOGIN,
    BORROWER_LOGIN,
    LOG_OUT,
    USER_REGISTER,
    GET_PROFILE,
    GET_PROFILE_PERSONAL_INFO,
    UPDATE_PROFILE_PERSONAL_INFO,
    LENDER_REGISTRATION,
    LENDER_LOGIN,
    GET_ALL_DOC_OF_CONSUMER,
    PROFILE_AVATAR,
    PROFILE_UPLOAD,
    SAVE_STATES,
    GET_EMAIL,
    USER_PROFILE_INFO,
    GET_CONSUMER_DATA,
    IS_SALE_PROPERTY_FLOW,
} from '../types/userTypes';
import isNull from 'lodash/fp/isNull';

const initialState = {
    isAutheticated: false,
    accessToken: '',
    lenderAccessToken: '',
    lenderAuthenticated: false,
    name: '',
    email: '',
    imageURL: '',
    borrower: {},
    userType: '',
    userReg: {},
    profileData: {},
    lenderReg: {},
    lenderSign: {},
    personelInfo: {},
    saveProfile: {},
    totalStates: {},
    profileInfo: null,
    dashboardScenarios: null,
    consumerData: null,
    isSalePropertyFlow: getValueFromLocalStorage(localStorageKeys.isSalePropertyFlow) ?? true,
    propertyKeyName: isNull(getValueFromLocalStorage(localStorageKeys.isSalePropertyFlow))
        ? 'saleProperty'
        : getValueFromLocalStorage(localStorageKeys.isSalePropertyFlow)
        ? 'saleProperty'
        : 'rentalProperty',
};

function userReducer(state = initialState, action: any) {
    switch (action.type) {
        case GET_EMAIL:
            return {
                ...state,
                email: action.payload,
            };

        case AUTHENTICATION:
            return {
                ...state,
                isAutheticated: action.payload,
            };
        case GOOGLE_LOGIN: {
            return {
                ...state,
                isAutheticated: true,
                accessToken: action.payload.accessToken,
                name: action.payload.profileObj.name,
                email: action.payload.profileObj.email,
                imageURL: action.payload.profileObj.imageUrl,
            };
        }
        case BORROWER_LOGIN: {
            return {
                ...state,
                isAutheticated: true,
                accessToken: action.payload.token,
                userType: 'user',
                borrower: action.payload,
            };
        }
        case USER_REGISTER: {
            return {
                ...state,
                userReg: action.payload,
            };
        }
        case LENDER_REGISTRATION: {
            return {
                ...state,
                lenderReg: action.payload,
            };
        }
        case LENDER_LOGIN: {
            return {
                ...state,
                lenderSign: action.payload,
                lenderAccessToken: action.payload.token,
                lenderAuthenticated: true,
            };
        }
        case UPDATE_PROFILE_PERSONAL_INFO: {
            return {
                ...state,
                personelInfoUpdate: action.payload,
            };
        }
        case GET_PROFILE: {
            return {
                ...state,
                profileData: action.payload,
            };
        }
        case PROFILE_UPLOAD: {
            return {
                ...state,
                saveProfile: action.payload,
            };
        }

        case GET_PROFILE_PERSONAL_INFO: {
            return {
                ...state,
                personelInfo: action.payload,
            };
        }

        case GET_ALL_DOC_OF_CONSUMER: {
            return {
                ...state,
                getPersonelInfoDocs: action.payload,
            };
        }

        case 'GET_PROFILE_DOCS': {
            return {
                ...state,
                personelInfoDocs: action.payload,
            };
        }

        case LOG_OUT: {
            localStorage.removeItem(action.payload);
            return {
                ...state,
                ...initialState,
            };
        }

        case PROFILE_AVATAR: {
            return {
                ...state,
                profileAvatar: action.payload,
            };
        }
        case SAVE_STATES: {
            return {
                ...state,
                totalStates: action.payload,
            };
        }

        case USER_PROFILE_INFO: {
            return {
                ...state,
                profileInfo: action.payload,
            };
        }

        case GET_CONSUMER_DATA: {
            return {
                ...state,
                consumerData: action.payload,
            };
        }

        case 'DASHBOARD_SCENERIO': {
            return {
                ...state,
                dashboardScenarios: action?.payload,
            };
        }

        case IS_SALE_PROPERTY_FLOW: {
            return {
                ...state,
                isSalePropertyFlow: action.payload,
            };
        }

        default:
            return state;
    }
}

export default userReducer;
