import { FORM_VALIDATION, DECREASE_COUNTER, INCREASE_COUNTER,GET_VALIDATE_COUNTER } from "../types";


const initialState = {
    isFormValidate: true,
    validateCounter: 0
};

function formValidationReducer(state = initialState, action: any) {
    switch (action.type) {
        case FORM_VALIDATION:
            return {
                ...state,
                isFormValidate: action.payload
            };
        case GET_VALIDATE_COUNTER:
            return {
                ...state,
                validateCounter: action.payload,
            }
        case INCREASE_COUNTER:
            
            return {
                ...state,
                validateCounter: state.validateCounter + 1
            };
            
        case DECREASE_COUNTER:
            if(state.validateCounter < 0){
                state.validateCounter = 1;
            }
            
            return {
                ...state,
                validateCounter: state.validateCounter - 1
            };

        default: {
            return {
                ...state
            }
        }
    }
}
export default formValidationReducer;