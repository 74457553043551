import logo from './assets/images/logo.png';
import login from './assets/images/login.png';
import homeimg from './assets/images/homeImg.jpg';
import mainimg from './assets/images/main.png';
import dashcard1 from './assets/images/Image_18.png';
import dashcard2 from './assets/images/Image_19.png';
import userpro from './assets/images/user-profile.png';
import helpimg from './assets/images/help.png';
import lenderimg from './assets/images/lender.png';
import profileimg from './assets/images/profilehelp.png';
import roadmap from './assets/images/Roadmap.jpg';
import auctionImg from './assets/images/auctionImg.png';
import house1 from './assets/images/House1.png';
import house2 from './assets/images/House2.png';
import house3 from './assets/images/House3.png';
import house4 from './assets/images/House4.png';
import house5 from './assets/images/House5.png';
import splashScreen from './assets/images/splashscreen.png';
import limitedOffer from './assets/images/limited-time-offer.png';
import appstore from './assets/images/appstore.png';
import playstore from './assets/images/playstore.png';

const Images = {
    Logo: logo,
    Login: login,
    HomeImg: homeimg,
    mainImg: mainimg,
    DashCard1: dashcard1,
    DashCard2: dashcard2,
    userPro: userpro,
    helpImg: helpimg,
    lender: lenderimg,
    profileHelp: profileimg,
    roadmap,
    auctionImg,
    house1,
    house2,
    house3,
    house4,
    house5,
    splashScreen,
    limitedOffer,
    appstore,
    playstore,
};

export default Images;
