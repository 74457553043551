import { COMMON_FILE_UPLOAD } from "../types";

const initialState = {};

function commonFileUploadReducer(state = initialState, action: any) {
  switch (action.type) {
    case COMMON_FILE_UPLOAD: {
      return {
        ...state,
        fileToUpload: action.payload
      };
    }
    default:
      return state;
  }
}

export default commonFileUploadReducer;
