import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { routingEndPoints } from './utility/routingEndPoints';
import './theme/assets/css/common.scss';
import { CometChat } from '@cometchat-pro/chat';
import { cometChatCredentials } from './utility/cometChatCredentials';
import Login from './Views/Login/Login';
import Signup from './Views/Signup/Signup';
import { ToastContainer } from 'react-toastify';
import { Close } from '@material-ui/icons';
import 'react-toastify/dist/ReactToastify.css';
import PrivateRoute from './Routes/PrivateRoutes/PrivateRoute';
import InitialLoader from './components/InitialLoader';
import { usePushNotification } from './hooks/usePushNotification';
import CacheBusting from './components/cacheBusting';

const ContactScreen = React.lazy(() => import('./containers/ContactScreen'));
const NewsRoomScreen = React.lazy(() => import('./containers/NewsRoomScreen'));
const PlanScreen = React.lazy(() => import('./containers/PlanScreen'));
const FAQScreen = React.lazy(() => import('./containers/FAQScreen'));
const RoadmapScreen = React.lazy(() => import('./containers/RoadmapScreen'));
const EmailVerificationScreen = React.lazy(() => import('./containers/EmailVerificationScreen'));
const ForgotPasswordScreen = React.lazy(() => import('./containers/ForgotPasswordScreen'));
const SendForgotPassLinkScreen = React.lazy(() => import('./containers/SendForgotPassLinkScreen'));
const Dashboard = React.lazy(() => import('./Views/Index'));
const NewDashboard = React.lazy(() => import('./Views/NewDashboard/index'));
const CosignerEmailVerification = React.lazy(() => import('./containers/CosignerEmailVerification'));
const DeepLinksRedirection = React.lazy(() => import('./Views/DeepLinksRedirection'));
const PropertiesViewAnon = React.lazy(() => import('./Views/Houses'));
const ContactUs = React.lazy(() => import('./Views/contactus'));
const PurchaseProperty = React.lazy(() => import('./Views/purchaseProperty'));

const Routing: React.FunctionComponent<{}> = () => {
    useEffect(() => {
        usePushNotification();
        var appID = cometChatCredentials.appID;
        var region = cometChatCredentials.appRegion;
        var appSetting = new CometChat.AppSettingsBuilder().subscribePresenceForAllUsers().setRegion(region).build();
        CometChat.init(appID, appSetting)
            .then(() => console.log('CC Initialize done!'))
            .catch((err) => console.error(err));
    }, []);
    const CloseButton = ({ closeToast }: { closeToast: any }) => <Close onClick={closeToast} />;

    return (
        <CacheBusting>
            {({
                loading,
                isLatestVersion,
                refreshCacheAndReload,
            }: {
                loading: boolean;
                isLatestVersion: boolean;
                refreshCacheAndReload: () => void;
            }) => {
                if (loading) return null;
                if (!loading && !isLatestVersion) {
                    // You can decide how and when you want to force reload
                    refreshCacheAndReload();
                }
                return (
                    <React.Fragment>
                        <ToastContainer closeButton={CloseButton} />
                        <InitialLoader initial={false} />

                        <Router>
                            <Suspense fallback={<InitialLoader initial={true} />}>
                                <Switch>
                                    <Route exact path="/" component={PropertiesViewAnon} />
                                    <Route exact path={routingEndPoints.login} component={Login} />
                                    <Route path="/email-verification" component={EmailVerificationScreen} />
                                    <Route path="/user/cosigner" component={CosignerEmailVerification} />
                                    <Route exact path={routingEndPoints.borrowerSignUp} component={Signup} />
                                    <Route exact path={routingEndPoints.contact} component={ContactScreen} />
                                    <Route exact path={routingEndPoints.newsroom} component={NewsRoomScreen} />
                                    <Route exact path={routingEndPoints.plan} component={PlanScreen} />
                                    <Route exact path={routingEndPoints.faq} component={FAQScreen} />
                                    <Route exact path={routingEndPoints.roadmap} component={RoadmapScreen} />

                                    <Route path={routingEndPoints.resetPass} component={ForgotPasswordScreen} />
                                    <Route path={routingEndPoints.forgotPass} component={SendForgotPassLinkScreen} />
                                    <Route path={routingEndPoints.resetPass} component={ForgotPasswordScreen} />
                                    <Route path={routingEndPoints.forgotPass} component={SendForgotPassLinkScreen} />
                                    <Route path={`${routingEndPoints.dlta}:pathname`} component={DeepLinksRedirection} />

                                    <Route exact path={routingEndPoints.contactUs} component={ContactUs} />
                                    <Route exact path={routingEndPoints.purchaseProperty} component={PurchaseProperty} />

                                    <PrivateRoute path="/dashboard/" component={Dashboard} />
                                </Switch>
                            </Suspense>
                        </Router>
                    </React.Fragment>
                );
            }}
        </CacheBusting>
    );
};

export default withTranslation('common')(Routing);
