import {
    GET_ALL_DROPDOWN_INCOME,
    GET_ALL_INCOME_DATA,
    UPDATE_INCOME_DATE,
    GET_ALL_INCOME_CARD_DATA,
    DATA_LOADED
} from "../types";

const initialState = {
    allDropdownValues: {},
    allIncomeData: {},
    allIncomeCardData: {},
    showLoader: true,
}

function incomeNewReducer(state = initialState, action: any) {
    switch (action.type) {
        case GET_ALL_INCOME_DATA:
            return {
                ...state,
                allIncomeData: action.payload
            };


        case GET_ALL_DROPDOWN_INCOME:
            return {
                ...state,
                allDropdownValues: action.payload.data.responseObject
            };

        case DATA_LOADED:
            return {
                ...state,
                showLoader: action.payload
            };

        case GET_ALL_INCOME_CARD_DATA:
            return {
                ...state,
                allIncomeCardData: action.payload
            };

        default: {
            return {
                ...state,
            }
        }
    }
}
export default incomeNewReducer;