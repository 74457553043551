import { BID_DOCUMENTS_LIST, LOAN_LIST, PROPERTY_LIST } from '../types/loanTypes';

const initialState = {
    allLoanList: null,
    propertyList: null,
    documentList: null,
};

const loanReducer = (state = initialState, action: any) => {
    const newState = { ...state };
    switch (action.type) {
        case LOAN_LIST: {
            return { ...newState, allLoanList: action.payload };
        }
        case PROPERTY_LIST: {
            return { ...newState, propertyList: action.payload };
        }
        case BID_DOCUMENTS_LIST: {
            return { ...newState, documentList: action.payload };
        }
        default: {
            return { ...newState };
        }
    }
};

export default loanReducer;
