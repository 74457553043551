import { Box, Link, makeStyles } from '@material-ui/core';
import React from 'react';
import { colors } from '../../theme';
const { config } = require(`../../config/env.${process.env.REACT_APP_ENVIRONMENT}`);

const useStyles = makeStyles({
    rootWrapper: {
        backgroundColor: colors.white,
        width: '100%',
    },
    wrapper: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '60.125rem',
        padding: '0.1rem 0.75rem',
        '@media (max-width: 767px)': {
            '& a': {
                display: 'block',
                textAlign: 'center',
            },
        },
    },
    link: {
        color: colors.primary,
        textDecoration: 'underline',
        marginRight: '0.75rem',
        fontSize: '0.8rem',
    },
});

const FooterLinks = () => {
    const classes = useStyles();
    return (
        <Box className={classes.rootWrapper}>
            <Box className={classes.wrapper}>
                <Link className={classes.link} href={`${config.API_URL}/document/static/SleekfinPrivacyPolicy.html`}>
                    Privacy Policy
                </Link>
                <Link className={classes.link} href={`${config.API_URL}/document/static/UserTermsAndConditions.html`}>
                    Terms & Conditions
                </Link>
            </Box>
            <Box className={classes.wrapper}>
                <Link className={classes.link} href={`${config.API_URL}/document/static/DataRetentionPolicy.html`}>
                    Data Deletion Policy
                </Link>
            </Box>
        </Box>
    );
};

export default FooterLinks;
