const colors = {
    red: '#ff0000',
    gray: '#666666',
    blue: '#0000ff',
    colorPrimary: '#3179be',
    snow: '#ffffff',
    dark: '#000000',
    shadowOne: 'rgba(255,216,68,0.68)',
    headerBgColor: 'rgba(22,24,26,0.85)',
    borderColor: '#c4c4c4',
    green: '#3179be',
    darkGreen: '#4B8553',
    lightGray: '#DEDEDE',
    white: '#fff',
    darkblue: '#11519D',
    darkpink: '#F6528C',
    darknavy: '#2F3C51',
    darkgreeny: '#2ADDB8',
    darkyellow: '#FFB05D',
    lightSky: '#3CD5C2',
    sky: '#97CDC6',
    lightblue: '#8EA2E1',
    pink: '#da17eb',
    mediumGray: '#A2A2A2',
    primary: '#3179be',
    darkGray: '#707070',
    black: '#000000',
    bodySky: '#e7f3ff',
    bodyGray: '#e6e6e6',
    textColor: '#707070',
    orange: '#fd7e14',
    lightYellow: '#e1cca1',
    medYellow: '#dca843',
    darkYellow: '#b57700',
    sleekfinYellow: '#fee400',
    gray2: '#eee',
    lightestGray: '#EBF6FF',
};

export default colors;
