import * as yup from 'yup';
export const signupValidation = {
    validationSetting: yup.object().shape({
        email: yup.string().required().email(),
        password: yup
            .string()
            .required()
            .matches(
                /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$/,
                'Minimum eight characters, at least one letter, one number and one special character'
            ),
        confirmPassword: yup
            .string()
            .required()
            .oneOf([yup.ref('password'), null as any], 'Passwords must match'),
    }),
};
