import { Grid, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { initializeFirebase } from './initializeFirebase';
import { facebookProvider } from './facebookProvider';
import { googleProvider } from './googleProvider';
import { signInWithPopup } from './signInWithPopup';
import { useDispatch } from 'react-redux';
import { builderSocialRegisterAPIcall } from '../../redux/actions/socialAction';
import { socialRegisterTypes } from '../../Interfaces/authTypes';
import SocialButton from './SocialButton';
import Icons from '../../theme/icons';

const useStyles = makeStyles({
    container: {
        maxWidth: '6rem',
        marginTop: '1rem',
    },
    icon: {
        width: '1.5rem',
    },
});

const FbGoogleRegister = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        /*##### Initialize Firebase ####*/
        initializeFirebase();
    }, []);

    const handleSocialRegister = (result: any, type: string) => {
        const { credential, additionalUserInfo, user } = result;
        const { profile } = additionalUserInfo;
        const { name, email, first_name, last_name } = profile;
        const { phoneNumber } = user;
        const { accessToken } = credential;
        const data: socialRegisterTypes = {
            authToken: accessToken,
            builderName: name,
            email: email,
            firstName: first_name,
            lastName: last_name,
            licenseNumber: '',
            phoneNumber: phoneNumber,
            role: '',
            socialPlatform: type,
        };
        dispatch(builderSocialRegisterAPIcall(data));
    };

    const handleFaceBookClick = () => {
        signInWithPopup(facebookProvider)
            .then((res: any) => {
                console.log(res);
                handleSocialRegister(res, 'facebook');
            })
            .catch((err: any) => {
                console.log(err);
            });
    };

    const handleGoogleClick = () => {
        signInWithPopup(googleProvider)
            .then((res: any) => {
                console.log(res);
                handleSocialRegister(res, 'google');
            })
            .catch((err: any) => {
                console.log(err);
            });
    };

    return (
        <Grid container alignItems="center" justify="center">
            <Grid container alignItems="center" justify="space-between" className={classes.container}>
                <SocialButton onClick={handleFaceBookClick} img={Icons.facebookLogin} />
                <SocialButton onClick={handleGoogleClick} img={Icons.googleLogin} />
            </Grid>
        </Grid>
    );
};

export default FbGoogleRegister;
