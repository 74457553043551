import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    error: {
        fontSize: '0.7rem',
        color: 'red',
        marginTop: '-1rem',
        marginBottom: '0.5rem',
    },
});

interface props {
    error: string;
}
const ShowErrors: React.FC<props> = ({ error }) => {
    const classes = useStyles();
    return <Typography className={`msg-error ${classes.error}`}>{error}</Typography>;
};

export default ShowErrors;
