const GET_CREDIT_SCORE_DATA: string = "GET_CREDIT_SCORE_DATA";

const GET_LIABILITY_DATA: string = "GET_LIABILITY_DATA";
const INSERT_LIABILITY_DATA: string = "INSERT_LIABILITY_DATA";
const UPDATE_LIABILITY_DATA: string = "UPDATE_LIABILITY_DATA";
const DETELE_LIABILITY_DATA: string = "DETELE_LIABILITY_DATA";
const GET_LIABILITY_CARD_DATA: string = "GET_LIABILITY_CARD_DATA";

const GET_INCOME_DATA: string = "GET_INCOME_DATA";
const INSERT_INCOME_DATA: string = "INSERT_INCOME_DATA";
const UPDATE_INCOME_DATA: string = "UPDATE_INCOME_DATA";
const DETELE_INCOME_DATA: string = "DETELE_INCOME_DATA";
const UPDATE_OTHER_INCOME_DATA: string = "UPDATE_OTHER_INCOME_DATA";
const GET_OTHER_INCOME_DATA: string = "GET_OTHER_INCOME_DATA";

const GET_NETWORTH_DATA: string = "GET_NETWORTH_DATA";
const INSERT_NETWORTH_DATA: string = "INSERT_NETWORTH_DATA";
const UPDATE_NETWORTH_DATA: string = "UPDATE_NETWORTH_DATA";
const DETELE_NETWORTH_DATA: string = "DETELE_NETWORTH_DATA";

const GET_NETWORTH_DATA_PROPERTY: string = "GET_NETWORTH_DATA_PROPERTY";
const INSERT_NETWORTH_DATA_PROPERTY: string = "INSERT_NETWORTH_DATA_PROPERTY";
const UPDATE_NETWORTH_DATA_PROPERTY: string = "UPDATE_NETWORTH_DATA_PROPERTY";
const DETELE_NETWORTH_DATA_PROPERTY: string = "DETELE_NETWORTH_DATA_PROPERTY";
const GET_NETWORTH_CARD_DATA: string = "GET_NETWORTH_CARD_DATA";

const DATA_LOADED: string = "DATA_LOADED";

export {
  GET_CREDIT_SCORE_DATA,
  GET_INCOME_DATA,
  INSERT_INCOME_DATA,
  UPDATE_INCOME_DATA,
  DETELE_INCOME_DATA,
  UPDATE_OTHER_INCOME_DATA,
  GET_OTHER_INCOME_DATA,
  GET_NETWORTH_DATA_PROPERTY,
  INSERT_NETWORTH_DATA_PROPERTY,
  UPDATE_NETWORTH_DATA_PROPERTY,
  DETELE_NETWORTH_DATA_PROPERTY,
  GET_NETWORTH_DATA,
  INSERT_NETWORTH_DATA,
  UPDATE_NETWORTH_DATA,
  DETELE_NETWORTH_DATA,
  GET_LIABILITY_DATA,
  GET_LIABILITY_CARD_DATA,
  INSERT_LIABILITY_DATA,
  UPDATE_LIABILITY_DATA,
  DETELE_LIABILITY_DATA,
  GET_NETWORTH_CARD_DATA
};
