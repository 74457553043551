import { Grid, IconButton, makeStyles } from '@material-ui/core';
import React, { useEffect, FC } from 'react';
import { initializeFirebase } from './initializeFirebase';
import { facebookProvider } from './facebookProvider';
import { googleProvider } from './googleProvider';
import { signInWithPopup } from './signInWithPopup';
import { useDispatch } from 'react-redux';
import { builderSocialLoginAPIcall } from '../../redux/actions/socialAction';
import { socialLoginTypes } from '../../Interfaces/authTypes';
import SocialButton from './SocialButton';
import { useHistory } from 'react-router-dom';
import Icons from '../../theme/icons';
import ModalComponent from '../Modal/ModalComponent';

const useStyles = makeStyles({
    container: {
        maxWidth: '10rem',
        marginTop: '1rem',
    },
    icon: {
        width: '1.5rem',
    },
});

interface Props {
    handleMobileLogin: (value: boolean) => void;
}

const FbGoogleLogin: FC<Props> = ({ handleMobileLogin }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const routes = useHistory();
    useEffect(() => {
        /*##### Initialize Firebase ####*/
        initializeFirebase();
    }, []);

    const handleSocialRegister = (result: any, type: string) => {
        const { credential, additionalUserInfo } = result;
        const { profile } = additionalUserInfo;
        const { email } = profile;
        const { accessToken } = credential;
        const data: socialLoginTypes = {
            authToken: accessToken,
            email: email,
            socialPlatform: type,
        };
        dispatch(builderSocialLoginAPIcall(data, routes));
    };

    const handleFaceBookClick = () => {
        signInWithPopup(facebookProvider)
            .then((res: any) => {
                console.log(res);
                handleSocialRegister(res, 'facebook');
            })
            .catch((err: any) => {
                console.log(err);
            });
    };

    const handleGoogleClick = () => {
        signInWithPopup(googleProvider)
            .then((res: any) => {
                console.log(res);
                handleSocialRegister(res, 'google');
            })
            .catch((err: any) => {
                console.log(err);
            });
    };

    return (
        <Grid container alignItems="center" justify="center">
            <Grid container alignItems="center" justify="space-between" className={classes.container}>
                <SocialButton onClick={handleFaceBookClick} img={Icons.facebookLogin} />
                <SocialButton onClick={handleGoogleClick} img={Icons.googleLogin} />
                <SocialButton onClick={() => handleMobileLogin(true)} img={Icons.mobileIcon} />
            </Grid>
        </Grid>
    );
};

export default FbGoogleLogin;
