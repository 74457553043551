import { firebaseConfig } from './firebaseConfig';

export const initializeFirebase = () => {
    try {
        (window as any).firebase.initializeApp(firebaseConfig);
        (window as any).firebase.analytics();
    } catch (err) {
        console.log(err);
    }
};
